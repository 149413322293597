import { Link } from '@remix-run/react';
import type { ListItem } from '~/models/list-item.model';
import { convertDate } from '~/utils/convert-date';

const Card = (card: ListItem) => {
  return (
    <>
      <Link to={card.url ?? '/'}>
        <div className="card bg-white overflow-hidden rounded-lg">
          {card.imageUrl && (
            <div className="card-thumbnail overflow-hidden">
              <img src={card.imageUrl} alt={card.title} className="object-cover object-center h-full w-full" />
            </div>
          )}
          <div className="card-meta p-4">
            <div className="card-meta-head">
              {card.category && (
                <div className="tagline font-bold uppercase text-xs tracking-wider">{card.category}</div>
              )}
              <div className="title font-bold text-lg leading-snug">{card.title}</div>
              {card.description && <div className="description mt-2">{card.description}</div>}
            </div>
            {card.publishedDate && card.showDateOnCard &&  (
              <div className="date mt-8 text-xs">{convertDate(card.publishedDate, card.locale || 'en')}</div>
            )}
          </div>
        </div>
      </Link>
    </>
  );
};

export { Card };
