import type { Feature } from '~/models/feature-set.model';
import type { Link } from '~/models/link.model';
import type { Icon as IconModel } from '~/models/icon.model';
import { Button } from '../commons/Button';
import { Icon } from '../commons/Icon';
import Image from '~/components/commons/Image';

type FeatureGridProp = {
  content: Feature[];
};

type CardProp = {
  title?: string;
  link?: Link;
  descriptionRichText?: string;
  imageUrl?: string;
  icon?: IconModel;
};

const FeatureGrid = ({ content }: FeatureGridProp) => {
  return (
    <div className="grid sm:grid-cols-2 lg:grid-cols-4 gap-8 md:gap-x-4 md:gap-y-16 mb-8 md:mb-auto">
      {content?.map((feature: Feature, index: number) => (
        <Card
          key={index}
          title={feature.title}
          link={{ url: feature.linkUrl, text: feature.linkText, type: 'link' }}
          descriptionRichText={feature.descriptionRichText}
          imageUrl={feature.imageUrl}
          icon={feature.icon}
        />
      ))}
    </div>
  );
};

const Card = (card: CardProp) => {
  return (
    <div className="card flex flex-col justify-between">
      <div className="card-group flex flex-col gap-2">
        {card.imageUrl && !card.icon?.type && (
          <div className="card-thumbnail aspect-image rounded-sm overflow-hidden">
            <Image src={card.imageUrl} className="h-full w-full object-center object-cover" />
          </div>
        )}
        {card.icon?.type && card.icon?.name && (
          <div className="card-icon w-fit rounded">
            <Icon name={card.icon.name} type={card.icon.type || 'solid'} className="text-3xl" />
          </div>
        )}
        {card.imageUrl && card.icon?.type === 'image' && (
          <div className="card-icon icon-image aspect-square w-fit">
            <Image src={card.imageUrl} className="h-full w-full object-center object-contain" />
          </div>
        )}
        {card.title && <div className="card-title font-bold text-xl">{card.title}</div>}
        {card.descriptionRichText && (
          <div className="card-excerpt" dangerouslySetInnerHTML={{ __html: card.descriptionRichText }}></div>
        )}
      </div>
      {card.link?.url && (
        <div className="card-button mt-4">
          <Button
            to={card.link?.url}
            variant="text"
            type="link"
            size="md"
            className="rounded-none flex flex-row items-center gap-1 w-fit"
          >
            {card.link?.text}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FeatureGrid;
