import { NavFeatured } from './NavFeatured';
import { NavCard } from './NavCard';
import type { Link as LinkModel } from '~/models/link.model';
import type { LinkParent, LinkSection, NavigationHighlight } from '~/models/navigation.model';
import { NavSection } from './NavSection';
import { Link } from '@remix-run/react';
import { setTextColor, useBackground } from '~/utils/component-settings';
import { useRef } from 'react';
import { Button } from '~/components/commons/Button';

/**
 * Navbar Dropdown react component
 * @returns {any} Navbar Dropdown React component
 */
const Dropdown = (props: {
  items: LinkModel[];
  sections: LinkSection[];
  highlight: NavigationHighlight | undefined;
  type: string;
  parentPage: LinkParent | undefined;
}) => {
  const refHighlight = useRef<HTMLDivElement | null>(null);
  const textColor = setTextColor(props.highlight?.background?.text);
  useBackground(refHighlight, props.highlight?.background?.color, props.highlight?.background?.src);
  let LAYOUT;

  switch (props.highlight?.layout || 'normal') {
    case 'large':
      LAYOUT = {
        cards: `${props.highlight ? 'lg:grid-cols-2' : 'sm:grid-cols-4 lg:grid-cols-5 2xl:grid-cols-6'}`,
        wrapper: `${props.highlight ? 'md:flex' : 'md:grid md:grid-cols-6'}`,
        featured: 'md:col-span-3 2xl:col-span-2',
      };
      break;
    default:
      LAYOUT = {
        cards: `${
          props.highlight
            ? 'sm:grid-cols-2 md:col-span-4 xl:grid-cols-2'
            : 'sm:grid-cols-4 md:col-span-6 lg:grid-cols-5'
        }`,
        wrapper: 'md:grid md:grid-cols-6',
        featured: 'md:col-span-2',
      };
      break;
  }

  return (
    <>
      {props.type === 'largeMenu' && (
        <div className="submenu bg-white lg:w-full lg:absolute lg:right-0 lg:top-20">
          {props.parentPage?.title && props.parentPage?.url && (
            <div className="submenu-return px-4 py-2.5 md:px-8 border-b border-neutral">
              <Button
                  to={props.parentPage?.url}
                  type="link"
                  variant="text"
                  size="md"
                >
                  {props.parentPage?.title}
                </Button>
            </div>
          )}
          <div className={`submenu-wrapper ${LAYOUT.wrapper}`}>
            {props.sections.length > 0 && (
              <div className={`submenu-wrapper-items w-full grid grid-cols-1 ${LAYOUT.cards} p-4 gap-2 items-start`}>
                {props.sections?.map((section, index) => (
                  <NavSection data={section} key={index}>
                    {section?.links.map((item: LinkModel, index: number) => (
                      <li key={index}>
                        <Link to={item.url ?? '/'}>{item.text}</Link>
                      </li>
                    ))}
                  </NavSection>
                ))}
              </div>
            )}
            {props.items.length > 0 && (
              <div className={`w-full grid grid-cols-1 ${LAYOUT.cards} grid-flow-row p-4 gap-2 content-start`}>
                {props.items.map((item: LinkModel, index: number) => (
                  <NavCard data={item} key={index} />
                ))}
              </div>
            )}

            {props.highlight && (
              <div
                ref={refHighlight}
                className={`w-full submenu-highlight ${LAYOUT.featured} ${textColor} bg-cover bg-center`}
              >
                <NavFeatured data={props.highlight} />
              </div>
            )}
          </div>
        </div>
      )}

      {props.type === 'linkMenu' && (
        <>
          <div className="submenu links bg-white lg:absolute lg:top-20">
            <div className="submenu-wrapper lg:min-w-[20rem] lg:absolute lg:p-2 bg-white lg:rounded-b">
              {props.items.length > 0 && (
                <div className="submenu-wrapper-items relative flex flex-row p-4 lg:px-2 lg:pb-2 lg:pt-0">
                  <div className="item-links mt-2">
                    <ul className="flex flex-col gap-2">
                      {props.items?.map((item: LinkModel, index: number) => (
                        <li key={index}>
                          <Link to={item.url ?? '/'}>{item.text}</Link>
                        </li>
                      ))}
                    </ul>
                  </div>
                </div>
              )}
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default Dropdown;
