import type { FeedComponent, RenderFeedSettings } from '~/models/feed.model';
import type { Category } from '~/models/category.model';
import { useEffect, useRef, useState } from 'react';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';
import { Button } from './commons/Button';
import { FeedCards, FeedGrid, FeedSidebar, FeedList } from './feed/_layouts';
import { PaginationInput } from './commons/PaginationInput';
import { Form, useFetcher, useLocation, useSearchParams } from '@remix-run/react';
import { Select } from './commons/Select';
import { Input } from './commons/Input';
import { getLocaleFromPath } from '~/utils/locale';
import type { ListResult } from '~/models/list-result.model';
import type { ListItem } from '~/models/list-item.model';

const Feed = ({ settings, section, listId, categories, highlights, items }: FeedComponent) => {
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const fetcher = useFetcher<ListResult<ListItem>>();
  const refParent = useRef<HTMLDivElement | null>(null);
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  const layout = setLayoutSwitch('feed', settings?.layout);
  const locale = getLocaleFromPath(location.pathname);
  const q = searchParams.get('q') || '';
  const feedType = settings?.type || 'default';
  const page = searchParams.get('page') !== null ? searchParams.get('page') : '1';
  const pageQuery = page ? `&page=${page}` : '';
  const category = searchParams.get('category') || '';
  const categoryQuery = category ? `&category=${category}` : '';
  const formRef = useRef<HTMLFormElement>(null);
  const [query, setQuery] = useState<string>(q);

  const searchResults: number = (fetcher.data?.pagination?.total || 0) as number;
  const fetchData = () => {
    const listQuery = listId !== undefined && listId !== null && listId >= 0 ? `&listId=${listId}` : '';
    const generalSearchQuery = section?.generalSearch ? '&generalSearch=true' : '';
    const actionUrl = `/search/search?q=${encodeURI(q || '')}${categoryQuery}${listQuery}${pageQuery}${generalSearchQuery}`;

    fetcher.load(actionUrl);
  };

const isMounted = useRef(false);

useEffect(() => {
  isMounted.current = true;
  setQuery(q);

  return () => {
    isMounted.current = false;
  };
}, [q]);

useEffect(() => {
  fetchData();
}, [location.pathname, location.search, listId, section?.generalSearch]);

  /**
    * Locale strings
   */
  let localeFilterBy,
    localeSearch,
    localeSearchDefault,
    localeSearchResults,
    localeEmptySearchResults,
    localePostsSuggestions;

  switch (locale) {
    case 'pt':
      localeFilterBy = 'Filtrar por';
      localeSearch = 'Pesquise...';
      localeSearchDefault = 'Pesquisa';
      localeSearchResults = 'Resultados da pesquisa';
      localeEmptySearchResults = 'Sem resultados na pesquisa';
      localePostsSuggestions = 'Não se preocupe. Selecionamos alguns tópicos que poderá gostar.';
      break;
    case 'es':
      localeFilterBy = 'Filtrado por';
      localeSearch = 'Búsqueda...';
      localeSearchDefault = 'Búsqueda';
      localeSearchResults = 'Resultados de la búsqueda';
      localeEmptySearchResults = 'Sin resultados en la búsqueda';
      localePostsSuggestions = 'No te preocupes. Hemos seleccionado algunos temas que podrías disfrutar.';
      break;
    default:
      localeFilterBy = 'Filter by';
      localeSearch = 'Search...';
      localeSearchDefault = 'Search';
      localeSearchResults = 'Search results';
      localeEmptySearchResults = 'No results in search';
      localePostsSuggestions = 'Don\'t worry. We\'ve curated topics that you might like.';
  }

  /**
    * Render feed component based on layout
  */
 const RenderFeed = ( { list, layout, highlights }: RenderFeedSettings ) => {
    switch (layout) {
      case 'grid':
        return <FeedGrid list={list} />;
      case 'sidebar':
        return <FeedSidebar list={list} highlights={highlights} />;
      case 'list':
        return <FeedList list={list} />;
      default:
        return <FeedCards list={list} />;
    }
  };

  return (
    <div ref={refParent} data-layout={layout} className="feed s-parent">
      <div className={`c-wrapper flex flex-col w-full mx-auto gap-8 px-8 py-12 ${textColor}`}>
        <div className="c-header flex flex-col md:flex-row gap-4">
          <div className="texts flex flex-col gap-2 w-full md:w-9/12">
            {feedType === 'default' ? (
              <>
                {section?.tagline &&
                  <div className="tagline text-sm">{section.tagline}</div>
                }
                {section?.title &&
                  <div className="title text-2xl md:text-3xl font-bold">{section.title}</div>
                }
                {section?.description &&
                  <div className="description text-lg mt-2">{section.description}</div>
                }
              </>
            ) : (
              <div className="title text-2xl md:text-3xl font-bold">
                {q.length > 0 ? (
                  searchResults.valueOf() > 0 ? (
                    <>
                      {localeSearchResults}{' '}
                      <span className="text-lg md:text-xl font-normal">
                        ({searchResults.toString()})
                      </span>
                    </>
                   ) : ( localeEmptySearchResults )
                  ) : ( localeSearchDefault )
                }
              </div>
            )}
          </div>
          {section?.buttons && section?.buttons?.items?.length > 0 &&
            section?.buttons?.position === 'top' && feedType === 'default' && (
            <div className="buttons w-full md:w-3/6 flex flex-row md:justify-end items-center">
              <div className="buttons flex flex-row gap-2 mt-4">
                {section.buttons.items?.map((button, index) => (
                  <Button key={index} type="link" variant={button.variant} size="md" to={button.url}>
                    {button.text}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="c-content">
          <Form
            ref={formRef}
            method="get"
            action={location.pathname}
            className="w-full max-w-screen-md mx-auto flex flex-row gap-4 sm:gap-8 justify-center items-center mb-8 md:mb-16"
          >
            {categories && categories?.length > 1 && (
              <Select defaultValue={category} name="category" onChange={() => formRef.current?.submit()}>
                <Select.Options>
                  <Select.Option>{localeFilterBy}</Select.Option>
                  {categories?.map((category: Category, index: number) => (
                    <Select.Option key={index} value={category.id}>
                      {category.name}
                    </Select.Option>
                  ))}
                </Select.Options>
              </Select>
            )}
            <Input name="q" type="search" value={query} onChange={(e) => setQuery(e.target.value)} placeholder={localeSearch} />
          </Form>

          {fetcher.state !== 'idle' ? (
            <></>
          ) : (
            <>
              {fetcher.data && (
                <RenderFeed list={fetcher.data?.items as ListItem[]} layout={layout} highlights={highlights} />
                )}
              {searchResults.valueOf() === 0 && q.length > 0 && (
                <>
                  <div className="search-curator-title text-lg lg:text-2xl text-center pt-4 pb-12 md:pt-12 md:pb-16 border-t">
                    {localePostsSuggestions}
                  </div>
                 <RenderFeed list={items as ListItem[]} layout={layout} highlights={highlights} />
                </>
              )}
            </>
          )}
        </div>
        {section?.buttons && section?.buttons?.items?.length > 0 &&
          section?.buttons?.position !== 'top' && feedType === 'default' && (
          <div className="c-bottom flex flex-col md:flex-row gap-4">
            <div className="buttons flex flex-row gap-2 mt-4">
              {section.buttons.items?.map((button, index) => (
                <Button key={index} type="link" variant={button.variant} size="md" to={button.url}>
                  {button.text}
                </Button>
              ))}
            </div>
          </div>
        )}
        {fetcher.data?.pagination && fetcher.data.pagination.totalPages > 1 && (
          <div className="c-pagination mt-8">
            <PaginationInput {...fetcher.data?.pagination} />
          </div>
        )}
      </div>
    </div>
  );
};

export { Feed };
