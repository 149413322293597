import type { ReactNode } from 'react';
import { Icon } from '~/components/commons/Icon';
import type { Icon as IconModel } from '~/models/icon.model';

type NavSectionProps = {
  data: {
    title?: string;
    icon?: IconModel;
  };
  children: ReactNode | ReactNode[];
};

/**
 * NavSection react component
 * @returns {any} NavSection React component
 */
const NavSection = ({ data, children }: NavSectionProps) => {
  return (
    <>
      <div className="submenu-item-section p-2 md:p-4">
        <div className="item-section flex flex-row justify-start flex-shrink-0">
          {data?.icon && (
            <div className="w-fit pr-4 pt-1">
              <Icon {...data?.icon} className="text-xl" />
            </div>
          )}
          <div className="w-full leading-tight flex flex-col gap-2">
            {data?.title && <div className="text-base font-bold">{data?.title}</div>}
          </div>
        </div>
        <div className="item-links mt-2">
          <ul className="flex flex-col gap-2">{children}</ul>
        </div>
      </div>
    </>
  );
};

export { NavSection };
