import type { HTMLInputTypeAttribute } from 'react';
import { useState } from 'react';
import { Button } from './Button';
import { EnterIcon, Icon } from './Icon';

type InputProps = {
  type?: HTMLInputTypeAttribute;
  defaultValue?: string | number;
  value?: string;
  disabled?: boolean;
  className?: string;
  size?: number;
  id?: string;
  name?: string;
  maxLength?: number;
  placeholder?: string;
  pattern?: string;
  required?: boolean;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onClick?: (e: React.MouseEvent<HTMLInputElement>) => void;
  children?: React.ReactNode;
};

function Input({
  type,
  disabled = false,
  size,
  id,
  name,
  maxLength,
  defaultValue,
  className,
  placeholder,
  pattern,
  required = false,
  onClick,
  ...more
}: InputProps) {

  const classDisabled = disabled ? 'opacity-50 pointer-events-none' : '';
  const baseStyle = 'w-full';
  let classBase = [baseStyle, classDisabled];
  const [fieldValue, setFieldValue] = useState<string>('');

  if (className) {
    classBase = [...classBase, ...className.split(' ')];
  }

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFieldValue(e.target.value);
  };

  const handleBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    if (!required) return;

    if (e.target.value.length > 0) {
      if (e.target.validity.valid) {
        e.target.classList.remove('field--error');
        e.target.classList.add('field--valid');
      } else {
        e.target.classList.remove('field--valid');
        e.target.classList.add('field--error');
      }
    } else {
      e.target.classList.remove('field--error', 'field--valid');
    }
  };

  return (
    <>
      <div className="relative w-full">
        <input
          type={type || 'text'}
          id={id}
          name={name}
          maxLength={maxLength}
          size={size}
          defaultValue={defaultValue}
          className={classBase.join(' ')}
          disabled={disabled}
          onClick={onClick}
          required={required}
          placeholder={placeholder}
          pattern={pattern}
          onChange={handleChange}
          onBlur={handleBlur}
          {...more}
        />
        {type === 'search' && (
          <div className="search-icon absolute right-1.5">
            {fieldValue ? (
              <Button
                type="submit"
                size="none"
                variant="none"
                className="bg-neutral-50 border border-neutral-100"
              >
                <EnterIcon />
              </Button>
            ) : (
              <Icon name="magnifying-glass" className="text-lg mr-1.5" />
            )}
          </div>
        )}
      </div>
    </>
  );
}

export { Input };
