import type { FeatureSetComponent } from '~/models/feature-set.model';
import type { FeedComponent } from '~/models/feed.model';
import type { VideoPlayerComponent } from '~/models/video-player.model';
import type { Component } from '~/models/component.model';
import { BusinessMetrics } from '~/components/business-metrics';
import { Clients } from '~/components/clients';
import { Cta } from '~/components/cta';
import { Collapse } from '~/components/collapse';
import { Form } from '~/components/form';
import { FeatureHighlight } from '~/components/feature-highlight';
import { FeatureLists } from '~/components/feature-lists';
import { FeatureSet } from '~/components/feature-set';
import { Hero } from '~/components/hero';
import { Header } from '~/components/header';
import { Iframe } from '~/components/iframe';
import { IframeForm } from '~/components/iframe-form';
import { ImageCarroussel } from '~/components/image-carroussel';
import { People } from '~/components/people';
import { Quote } from '~/components/quote';
import { RichText } from '~/components/rich-text';
import { TestimonialCarroussel } from '~/components/testimonial-carroussel';
import { Feed } from '~/components/feed';
import { Stacked } from '~/components/stacked';
import { VideoPlayer } from '~/components/commons/VideoPlayer';
import React from 'react';
import { RecentArticles } from '~/components/recent-articles';
import { PageList } from '~/components/page-list';
import { DsPricingSimulator } from '~/components/ds-pricing-simulator';
import { DsSupportTable } from '~/components/ds-support-table';
import { CookieScript } from '~/components/cookie-script';

const componentResolver = (component: Component, index: number) => {
  switch (component.type) {
    case 'hero':
      return React.createElement(Hero, { ...component, key: index });
    case 'clients':
      return React.createElement(Clients, { ...component, key: index });
    case 'businessMetrics':
      return React.createElement(BusinessMetrics, { ...component, key: index });
    case 'cta':
      return React.createElement(Cta, { ...component, key: index });
    case 'collapse':
      return React.createElement(Collapse, { ...component, key: index });
    case 'cookie-script':
      return React.createElement(CookieScript, { ...component, key: index });
    case 'form':
      return React.createElement(Form, { ...component, key: index });
    case 'header':
      return React.createElement(Header, { ...component, key: index });
    case 'featureHighlight':
      return React.createElement(FeatureHighlight, { ...component, key: index });
    case 'featureLists':
      return React.createElement(FeatureLists, { ...component, key: index });
    case 'featureSet':
      return React.createElement(FeatureSet, { ...(component as FeatureSetComponent), key: index });
    case 'iframe':
      return React.createElement(Iframe, { ...component, key: index });
    case 'iframe-form':
      return React.createElement(IframeForm, { ...component, key: index });
    case 'imageCarroussel':
      return React.createElement(ImageCarroussel, { ...component, key: index });
    case 'people':
      return React.createElement(People, { ...component, key: index });
    case 'quote':
      return React.createElement(Quote, { ...component, key: index });
    case 'richText':
      return React.createElement(RichText, { ...component, key: index });
    case 'testimonialCarroussel':
      return React.createElement(TestimonialCarroussel, { ...component, key: index });
    case 'feed':
      return React.createElement(Feed, { ...(component as FeedComponent), key: index });
    case 'stacked':
      return React.createElement(Stacked, { ...component, key: index });
    case 'videoPlayer':
      return React.createElement(VideoPlayer, { ...(component as VideoPlayerComponent), key: index });
    case 'recentArticles':
      return React.createElement(RecentArticles, { ...component, key: index });
    case 'page-list':
      return React.createElement(PageList, { ...component, key: index });
    case 'ds-pricing-simulator':
      return React.createElement(DsPricingSimulator, { ...component, key: index });
    case 'ds-support-table':
      return React.createElement(DsSupportTable, { ...component, key: index });
    default:
      return <></>;
  }
};

export { componentResolver };
