/* eslint-disable jsx-a11y/img-redundant-alt */
import type { ImageSettings } from '~/models/component-settings.model';

type HeaderDefaultProp = {
  content: ImageSettings;
};

const HeaderDefault = ({ content }: HeaderDefaultProp) => {
  return (
    <>
      {content?.src && <div className="image max-w-screen-lg overflow-hidden rounded-lg">
        <img src={content?.src} alt={content?.alt} className="object-cover object-center h-full w-full" />
      </div>
      }
    </>
  );
};

export default HeaderDefault;
