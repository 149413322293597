import React, { useCallback, useEffect, useRef, useState } from 'react';
import useEmblaCarousel from 'embla-carousel-react';
import ClassNames from 'embla-carousel-class-names';
import Autoplay from 'embla-carousel-autoplay';
import { PrevButton, NextButton, DotButton } from './Carousel/CarouselButtons';

type CarouselProps = {
  dots?: boolean;
  arrows?: boolean;
  className?: string;
  autoplay?: boolean;
  children: any;
};

const Carousel = ({
    dots = false,
    arrows = true,
    className = '',
    autoplay = true,
    children
  }: CarouselProps) => {
  const autoplayOptions = {
    delay: 4000,
    playOnInit: autoplay,
    stopOnInteraction: false,
    rootNode: (emblaRoot: { parentElement: any; }) => emblaRoot.parentElement
  };

  const autoplayCarousel = useRef(
    Autoplay(autoplayOptions)
  );

  const [carouselRef, carousel] = useEmblaCarousel(
    {
      loop: true,
      align: 0,
      skipSnaps: false,
    },
    [ClassNames(), autoplayCarousel.current],
  );

  const [prevBtnEnabled, setPrevBtnEnabled] = useState<boolean>(false);
  const [nextBtnEnabled, setNextBtnEnabled] = useState<boolean>(false);
  const [selectedIndex, setSelectedIndex] = useState<number>(0);
  const [scrollSnaps, setScrollSnaps] = useState<number[]>([]);

  const scrollPrev = useCallback(() => {
    if (!carousel) return;
    carousel.scrollPrev();
    autoplayCarousel.current.reset();
  }, [carousel]);
  const scrollNext = useCallback(() => {
    if (!carousel) return;
    carousel.scrollNext();
    autoplayCarousel.current.reset();
  }, [carousel]);
  const scrollTo = useCallback((index: number) => {
    if (!carousel) return;
    carousel.scrollTo(index);
    autoplayCarousel.current.reset();
  }, [carousel]);

  const onSelect = useCallback(() => {
    if (!carousel) return;
    setSelectedIndex(carousel.selectedScrollSnap());
    setPrevBtnEnabled(carousel.canScrollPrev());
    setNextBtnEnabled(carousel.canScrollNext());
  }, [carousel, setSelectedIndex]);

  const slides = React.Children.map(children, (child) => (child?.type?.displayName === 'Slide' ? child : null));

  useEffect(() => {
    if (!carousel) return;
    if (slides.length <= 1) {
      carousel.reInit({ draggable: false });
    } else {
      onSelect();
      setScrollSnaps(carousel.scrollSnapList());
      carousel.on('select', onSelect);
    }
  }, [carousel, slides.length, setScrollSnaps, onSelect]);

  return (
    <>
      <div className={`carousel ${className}`}>
        <div className="viewport" ref={carouselRef}>
          <div className={`container ${slides.length <= 1 ? 'is-single-slide' : ''}`}>
            {slides?.map((slide: any, index: number) => (
              <div key={index} className="slide">
                {slide}
              </div>
            ))}
          </div>
        </div>
        {arrows === true && slides.length > 1 && (
          <>
            <PrevButton onClick={scrollPrev} enabled={prevBtnEnabled || true} />
            <NextButton onClick={scrollNext} enabled={nextBtnEnabled || true} />
          </>
        )}
      </div>
      {dots === true && slides.length > 1 && (
        <div className="carousel--dots">
          {scrollSnaps?.map((_, index) => (
            <DotButton key={index} selected={index === selectedIndex} onClick={() => scrollTo(index)} />
          ))}
        </div>
      )}
    </>
  );
};

const Slide = ({ children }: any) => children;
Slide.displayName = 'Slide';
Carousel.Slide = Slide;

export { Carousel };
