type TextareaProps = {
  defaultValue?: string;
  disabled?: boolean;
  className?: string;
  cols?: number;
  rows?: number;
  form?: string;
  id?: string;
  name?: string;
  maxLength?: number;
  placeholder?: string;
  readonly?: boolean;
  required?: boolean;
};

function Textarea({
  defaultValue,
  disabled = false,
  className,
  cols,
  rows,
  form,
  id,
  name,
  maxLength,
  placeholder,
  readonly = false,
  required = false,
  ...more
}: TextareaProps) {
  const classDisabled = disabled ? 'opacity-50 pointer-events-none' : '';
  const baseStyle = 'w-full';
  let classBase = [baseStyle, classDisabled];

  if (className) {
    classBase = [...classBase, ...className.split(' ')];
  }

  return (
    <>
      <div className="relative">
        <textarea
          id={id}
          disabled={disabled}
          form={form}
          rows={rows}
          cols={cols}
          name={name}
          maxLength={maxLength}
          required={required}
          placeholder={placeholder}
          className={classBase.join(' ')}
          {...more}
        >
          {defaultValue}
        </textarea>
      </div>
    </>
  );
}

export { Textarea };
