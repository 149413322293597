import { useRef } from 'react';
import type { ImageCarrousselComponent } from '~/models/image-carroussel.model';
import { Carousel } from './commons/Carousel';
import { setTextColor, useBackground } from '~/utils/component-settings';

const ImageCarroussel = (props: ImageCarrousselComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const section = props.section;
  const images = props.images;
  const carouselSettings = settings?.carroussel;
  const textColor = setTextColor(props?.settings?.background?.text);
  useBackground(refParent, props?.settings?.background?.color, props?.settings?.background?.src);

  return (
    <div className={`content-view-carousel s-parent ${textColor}`} ref={refParent}>
      <div className="c-wrapper flex flex-col items-center w-full mx-auto px-8 py-12">
        {(section?.tagline || section?.title || section?.description) && (
        <div className="c-text w-full">
          <div className="texts flex flex-col gap-2 w-full md:w-9/12">
            {section?.tagline && <div className="tagline">{section?.tagline}</div>}
            {section?.title && <div className="title text-2xl md:text-3xl font-bold">{section?.title}</div>}
            {section?.description && <div className="description text-lg pb-8 md:pb-0 mt-4" dangerouslySetInnerHTML={{ __html: section?.description }}></div>}
          </div>
        </div>
        )}
        <div className="c-carousel">
          <Carousel
            arrows={carouselSettings?.arrows}
            dots={carouselSettings?.dots}
            autoplay={carouselSettings?.autoplay}
          >
            {images?.map((image, index) => (
              <Carousel.Slide key={index}>
                <div className="image w-full overflow-hidden">
                  <img
                    src={image.imageUrl}
                    alt={image.caption}
                    className="w-full object-cover object-center"
                  />
                </div>
                <div className="caption text-sm w-full md:w-5/6 pt-2 md:pt-3 px-2 h-8">{image.caption}</div>
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export { ImageCarroussel };
