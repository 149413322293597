import type { PageListComponent } from '~/models/page-list.model';
import { useRef } from 'react';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';
import { Button } from './commons/Button';
import { FeedCards, FeedGrid, FeedList } from './feed/_layouts';

const PageList = ({ settings, section, items }: PageListComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  const layout = setLayoutSwitch('pageList', settings?.layout);

  return (
    <div ref={refParent} data-layout={layout} className="feed s-parent">
      <div className={`c-wrapper flex flex-col w-full mx-auto gap-8 px-8 py-12 ${textColor}`}>
        <div className="c-header flex flex-col md:flex-row gap-4">
          <div className="texts flex flex-col gap-2 w-full md:w-9/12">
            {section?.tagline && <div className="tagline text-sm">{section.tagline}</div>}
            {section?.title && <div className="title text-2xl md:text-3xl font-bold">{section.title}</div>}
            {section?.descriptionRichText && (
              <div
                className="description text-lg mt-2"
                dangerouslySetInnerHTML={{ __html: section.descriptionRichText }}
              ></div>
            )}
          </div>
          {section?.buttons && section?.buttons?.items?.length > 0 && section?.buttons?.position === 'top' && (
            <div className="buttons w-full md:w-3/6 flex flex-row md:justify-end items-center">
              <div className="buttons flex flex-row gap-2 mt-4">
                {section.buttons.items?.map((button, index) => (
                  <Button key={index} type="link" variant={button.variant} size="md" to={button.url}>
                    {button.text}
                  </Button>
                ))}
              </div>
            </div>
          )}
        </div>
        <div className="c-content">
          {layout === 'cards' && <FeedCards list={items} />}
          {layout === 'grid' && <FeedGrid list={items} />}
          {layout === 'list' && <FeedList list={items} />}
        </div>
        {section?.buttons && section?.buttons?.items?.length > 0 && section?.buttons?.position !== 'top' && (
          <div className="c-bottom flex flex-col md:flex-row gap-4">
            <div className="buttons flex flex-row gap-2 mt-4">
              {section.buttons.items?.map((button, index) => (
                <Button key={index} type="link" variant={button.variant} size="md" to={button.url}>
                  {button.text}
                </Button>
              ))}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export { PageList };
