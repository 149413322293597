import type { Person, SocialMedia } from '~/models/people.model';
import { Button } from '../commons/Button';
import { Icon } from '../commons/Icon';

const ProfileCard = ({ imageUrl, jobTitle, name, socialMedia }: Person) => {
  return (
    <>
      <div className="profile">
        {imageUrl && (
          <div className="profile-image rounded-lg overflow-hidden h-80 md:h-96">
            <img src={imageUrl} alt={name} className="h-full w-full object-cover object-top" />
          </div>
        )}
        <div className="profile-info bg-white p-2 rounded w-11/12 mx-auto -mt-16 relative">
          <div className="name sm:text-lg font-bold">{name}</div>
          <div className="position text-sm sm:text-base min-h-[40px] sm:min-h-[50px]">{jobTitle}</div>
          {socialMedia && socialMedia.length > 0 && (
            <div className="links flex flex-row items-center gap-3 mt-2 sm:mt-4">
              {socialMedia?.map((link: SocialMedia, index: number) => (
                <ProfileLinks key={index} {...link} />
              ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

const ProfileLinks = ({ icon, url }: SocialMedia) => {
  return (
    <>
      <Button
        to={url}
        target="_blank"
        variant="none"
        aria-label={icon?.name}
        type="link"
        size="none"
        className="social-item rounded-none hover:opacity-80"
      >
        <Icon type={icon?.type || 'brands'} name={icon?.name} className="text-2xl" />
      </Button>
    </>
  );
};

export { ProfileCard };
