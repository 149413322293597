import type { ReactNode } from 'react';
import type { ShareProps } from '~/components/content/ShareArticle';
import type { Component } from '~/models/component.model';
import type { HeaderComponent } from '~/models/header.model';
import type { PageData } from '~/models/page-data.model';
import type { RelatedContentComponent } from '~/models/related-content.model';
import { ShareArticle } from '~/components/content/ShareArticle';
import { componentResolver } from '~/utils/component-resolver';
import { RelatedContent } from '~/components/content/RelatedContent';

const Page = (data: PageData) => {
  const components = renderComponents(data.components);
  const relatedContent = data.components?.find((x) => x.type === 'relatedContent') as RelatedContentComponent;
  const pageBgColor = data.backgroundColor;
  let share: ShareProps | undefined = undefined;

  if (data.layout === 'article') {
    const header = data.components?.find((x) => x.type === 'header') as HeaderComponent;
    share = {
      imageUrl: header?.image?.src,
      title: header?.section?.title,
    };
  }

  return data.layout === 'default' ? (
    <div className="page-view" style={pageBgColor ? { backgroundColor: pageBgColor } : undefined}>
      {components}
    </div>
  ) : (
    <ArticleLayout share={share} relatedContent={relatedContent} pageBgColor={pageBgColor}>
      {components}
      <></>
    </ArticleLayout>
  );
};

const ArticleLayout = (props: {
  share?: ShareProps;
  relatedContent?: RelatedContentComponent;
  children: ReactNode[];
  pageBgColor?: PageData['backgroundColor'];
}) => {
  return (
    <>
      <div className="content-view s-parent" style={props.pageBgColor ? { backgroundColor: props.pageBgColor } : undefined}>
        <div className="c-wrapper flex flex-col items-center w-full lg:max-w-screen-xl md:gap-8 lg:gap-16 mx-auto pb-12">
          <div className="c-content w-full">
            {...props.children}
            {props.share ? <ShareArticle title={props.share?.title} imageUrl={props.share?.imageUrl} /> : <></>}
          </div>
        </div>
      </div>
      {props.relatedContent && (
        <div className="content-related s-parent">
          <div className="c-wrapper flex flex-col w-full mx-auto gap-8 px-8 py-12">
            <RelatedContent {...props.relatedContent} />
          </div>
        </div>
      )}
    </>
  );
};

const renderComponents = (components?: Component[]) => {
  return (
    <>
      {components !== undefined &&
        components?.length > 0 &&
        components?.map((component, index) => componentResolver(component, index))}
    </>
  );
};

export { Page };
