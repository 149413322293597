import type { ListItem } from '~/models/list-item.model';
import { Card } from '../commons/Card';

type FeedGridProps = {
  list?: ListItem[];
};

const FeedList = ({ list }: FeedGridProps) => {
  return (
    <div className="flex flex-col gap-4">
      {list?.map((card: ListItem, index: number) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};

export default FeedList;
