/**
 * Convert Date format
 * @param {any} formatDate
 * @param {boolean} showHours
 * @returns {string} Converted Date format
 */
function convertDate(formatDate?: any, locale: string = 'en') {
  let date = new Date(formatDate);
  let month = new Intl.DateTimeFormat(locale, { month: 'long' }).format(date);
  let day = new Intl.DateTimeFormat(locale, { day: 'numeric' }).format(date);
  let year = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(date);
  let newDate;

  if (locale === 'pt' || locale === 'es') {
    newDate = day + ' ' + month + ', ' + year;
  } else {
    newDate = month + ' ' + day + ', ' + year;
  }

  return newDate;
}

function convertDates(start: Date, end?: Date, locale: string = 'en') {
  const startDate = new Date(start);
  let startMonth = new Intl.DateTimeFormat(locale, { month: 'long' }).format(startDate);
  let startDay = new Intl.DateTimeFormat(locale, { day: 'numeric' }).format(startDate);
  let startYear = new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(startDate);
  let endMonth = end ? new Intl.DateTimeFormat(locale, { month: 'long' }).format(new Date(end)) : '';
  let endDay = end ? new Intl.DateTimeFormat(locale, { day: 'numeric' }).format(new Date(end)) : '';
  let endYear = end ? new Intl.DateTimeFormat(locale, { year: 'numeric' }).format(new Date(end)) : '';

  let newDate;

  if (locale === 'pt' || locale === 'es') {
    if (startYear === endYear) {
      if (startMonth === endMonth) {
        if (startDay === endDay) {
          newDate = startDay + ' ' + startMonth + ', ' + startYear;
        } else {
          newDate = startDay + ' — ' + endDay + ' ' + startMonth + ', ' + startYear;
        }
      } else {
        newDate = startDay + ' ' + startMonth + (end ? ' — ' + endDay + ' ' + endMonth + ', ' + endYear : '');
      }
    } else {
      newDate =
        startDay + ' ' + startMonth + ', ' + startYear + (end ? ' — ' + endDay + ' ' + endMonth + ', ' + endYear : '');
    }
  } else {
    if (startYear === endYear) {
      if (startMonth === endMonth) {
        if (startDay === endDay) {
          newDate = startMonth + ' ' + startDay + ', ' + startYear;
        } else {
          newDate = startMonth + ' ' + startDay + ' — ' + endDay + ', ' + startYear;
        }
      } else {
        newDate = startMonth + ' ' + startDay + (end ? ' — ' + endMonth + ' ' + endDay + ', ' + endYear : '');
      }
    } else {
      newDate =
        startMonth + ' ' + startDay + ', ' + startYear + (end ? ' — ' + endMonth + ' ' + endDay + ', ' + endYear : '');
    }
  }

  return newDate;
}

export { convertDate, convertDates };
