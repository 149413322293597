/* eslint-disable jsx-a11y/img-redundant-alt */
import { useRef } from 'react';
import type { HeaderComponent } from '~/models/header.model';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';
import { HeaderDefault, HeaderCards } from './header/_layouts';
import { convertDate, convertDates } from '~/utils/convert-date';
import { Button } from './commons/Button';
import { Icon } from './commons/Icon';
import { Link } from '@remix-run/react';
import { ShareArticle } from './content/ShareArticle';

const Header = (props: HeaderComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const refSection = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const section = props.section;
  const meta = props.section?.meta;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  useBackground(refSection, section?.background?.color, section?.background?.src);
  const layout = setLayoutSwitch('header', settings?.layout);

  let localeTextWritten, localeTextOn, localeReturnTo;

  switch (meta?.locale) {
    case 'pt':
      localeTextWritten = 'Escrito por';
      localeTextOn = 'a';
      localeReturnTo = 'Voltar para';
      break;
    case 'es':
      localeTextWritten = 'Escrito por';
      localeTextOn = 'en';
      localeReturnTo = 'Volver al';
      break;
    default:
      localeTextWritten = 'Written by';
      localeTextOn = 'on';
      localeReturnTo = 'Back to';
  }

  return (
    <div
      ref={refParent}
      data-layout={layout}
      className={`header
        ${(layout === 'default' || layout === 'article') && settings?.size === 'big' ? 'image-big' : ''}
        ${(layout === 'default' || layout === 'article') && settings?.size === 'big' && settings?.returnTo?.show && settings?.returnTo?.position === 'top' ? 'has-button' : ''} s-parent`}
    >
      <div
        className={`c-wrapper relative mx-auto px-8 ${settings?.returnTo?.show && settings?.returnTo?.position === 'top' ? 'pt-24' : 'pt-12'} pb-12 ${textColor}`}
      >
        <div className="c-content relative z-10 mx-auto flex flex-col gap-8">
          {settings?.returnTo?.show &&
            settings?.returnTo?.url &&
            settings?.returnTo?.label &&
            (!settings?.returnTo?.position || settings?.returnTo?.position === 'top') && (
            <div className="back-list back-list--top">
              <Button
                type="link"
                variant="solid"
                className="btn-inverted rounded-md"
                size="xs"
                to={settings?.returnTo?.url}
              >
                <Icon type='solid' name='chevron-left' className='mr-1' /> {localeReturnTo} <strong>{settings?.returnTo?.label}</strong>
              </Button>
            </div>
          )}
          <div className="c-text texts flex flex-col gap-2 max-w-screen-lg">
            {(meta?.category || section?.tagline) && (
              <div className="tagline">{meta?.category || section?.tagline}</div>
            )}
            {(meta?.title || section?.title) && (
              <h1 className="title font-bold text-2xl md:text-3xl">{meta?.title || section?.title}</h1>
            )}
            {layout === 'article' && (settings?.showDate || settings?.authorName) && (
              <div className="meta-author text-base md:text-lg mt-2 mb-4">
                {settings?.authorName && (
                  <>{localeTextWritten} {settings?.authorName}{' '}</>
                )}
                {settings?.authorName && settings?.showDate && <>{localeTextOn} </>}
                {settings?.showDate && (
                  <span className="capitalize">{convertDate(meta?.publishedDate, meta?.locale || 'en')}</span>
                )}
              </div>
            )}
            {layout === 'default' && meta?.eventInfo && (
              <div className="meta-event flex flex-col md:flex-row gap-2 items-start md:justify-center mt-2 mb-4">
                {meta.eventInfo.startDate && (
                  <div className="date capitalize">
                    {convertDates(meta.eventInfo.startDate, meta.eventInfo.endDate, meta?.locale)}
                  </div>
                )}
                {meta?.eventInfo.location && <div className="location">{meta?.eventInfo.location}</div>}
              </div>
            )}
            {(meta?.description || section?.description) && (
              <div className="description text-base md:text-lg">{meta?.description || section?.description}</div>
            )}
            {layout === 'article' && (
              <div className="meta-author text-base md:text-lg">
                <ShareArticle atHeader={true} title={meta?.title || section?.title} locale={meta?.locale} />
              </div>
            )}
          </div>
          {layout !== 'minimal' && (
            <div className="c-layout w-full flex justify-center 2xl:max-w-screen-2xl mx-auto gap-8">
              {(layout === 'default' || layout === 'article') && !props.hideImageInArticleDetail && <HeaderDefault content={props.image || {}} />}
              {(layout === 'feature' || layout === 'cards') && <HeaderCards content={props.content} />}
            </div>
          )}
        </div>
        <div ref={refSection} className="c-section-background h-full w-full absolute top-0 left-0"></div>
      </div>
      {settings?.returnTo?.show &&
        settings?.returnTo?.url &&
        settings?.returnTo?.label &&
        settings?.returnTo?.position === 'bottom' && (
      <div className="back-list back-list--bottom">
        <div className="c-content">
          <Link to={settings?.returnTo?.url}>
            <span className="icon">
              <Icon type='solid' name='chevron-left' />
            </span>{settings?.returnTo?.label}
          </Link>
        </div>
      </div>
      )}
    </div>
  );
};

export { Header };
