import type { IframeComponent } from '~/models/iframe.model';

const Iframe = (props: IframeComponent) => {
  return (
    <div className="video">
      {props?.title &&
        <div className="c-header flex flex-col md:flex-row gap-4">
          <div className="texts flex flex-col gap-2 w-full md:w-9/12">
            <div className="title text-2xl md:text-3xl font-bold">{props.title}</div>
          </div>
        </div>
      }
      <div className="video-embed backdrop-blur-md">
        <iframe
          width="560"
          height="315"
          src={props.url}
          title={props.title}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      </div>
    </div>
  );
};

export { Iframe };