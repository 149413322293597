import type { ClientsComponent } from '~/models/clients.model';
import { useRef } from 'react';
import { Carousel } from './commons/Carousel';
import { ClientsLogos, ClientLogo } from './clients/ClientsLogos';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';

const Clients = (props: ClientsComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  const layout = setLayoutSwitch('clients', settings?.layout);

  return (
    <div ref={refParent} data-layout={layout} className="clients s-parent">
      <div className="c-wrapper flex flex-col w-full mx-auto px-8 py-12">
        <div className={`c-text ${textColor}`}>
          <div className="texts grid md:grid-cols-2 gap-2">
            <div className="title text-2xl md:text-3xl font-bold">{props.title}</div>
            {props.description && <div className={`description text-lg pb-8 md:pb-0 ${layout === 'default' ? 'mt-0':'mt-4'}`}>{props.description}</div>}
          </div>
        </div>
        <div className={`c-logos ${textColor}`}>
          <div className={`clients-logos-wrapper${settings?.carroussel?.active ? '-carousel' : ' grid grid-cols-2 md:grid-cols-4 lg:grid-cols-6'}`}>
            {settings?.carroussel?.active ? (
              <Carousel arrows={settings?.carroussel?.arrows} dots={settings?.carroussel?.dots} autoplay={settings?.carroussel?.autoplay}>
                {props.clients?.map((logo, index) => (
                  <Carousel.Slide key={index}>
                    <ClientLogo src={logo.imageUrl} alt={logo.name} grayscale={settings?.effects === 'grayscale'} />
                  </Carousel.Slide>
                ))}
              </Carousel>
            ) : (
              <ClientsLogos logos={props.clients} grayscale={settings?.effects === 'grayscale'} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export { Clients };
