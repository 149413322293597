import { Link } from '@remix-run/react';
import { Icon } from '~/components/commons/Icon';
import type { Link as LinkModel } from '~/models/link.model';

type NavCardProps = {
  data: LinkModel;
};

/**
 * NavCard react component
 * @returns {any} NavCard React component
 */
const NavCard = ({ data }: NavCardProps) => {
  return (
    <>
      <Link to={data.url ?? '/'} className="submenu-item">
        <div className={`submenu-item-wrapper h-full md:hover:bg-primary-50 md:hover:outline-primary-200 outline-1 outline outline-transparent p-2 transition-300 rounded flex flex-row justify-start flex-shrink-0 ${!data.description && 'items-center h-fit'}`}>
          {data.icon && (
            <div className="w-fit pr-4 -mt-0.5">
              <Icon {...data.icon} className="text-xl" />
            </div>
          )}
          <div className="w-full leading-tight flex flex-col gap-2">
            {data.text && <div className="text-base font-bold">{data.text}</div>}
            {data.description && <div className="font-normal text-sm opacity-80">{data.description}</div>}
          </div>
        </div>
      </Link>
    </>
  );
};

export { NavCard };
