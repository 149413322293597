/* eslint-disable jsx-a11y/img-redundant-alt */
import { Link } from '@remix-run/react';
import type { Content } from '~/models/header.model';

type CardHeaderProp = {
  content: Content | any;
};

const Card = ({ content }: CardHeaderProp) => {
  return (
    <>
      <Link to={content?.linkUrl ?? '/'} className="card-container w-full overflow-hidden rounded-lg">
        <div className="card flex flex-row">
          <div
            className={
              'card-meta flex flex-col justify-center p-4 sm:p-8' + (content.image?.src ? ' w-3/5 md:w-1/2' : '')
            }
          >
            <div className="tagline">{content?.category}</div>
            <div className="title text-lg sm:text-2xl">{content?.title}</div>
          </div>
          {content.image?.src && (
            <div className="card-image w-2/5 md:w-1/2 overflow-hidden max-h-96">
              <img
                src={content.image?.src}
                alt={content.image?.alt}
                className="object-center object-cover w-full h-full"
              />
            </div>
          )}
        </div>
      </Link>
    </>
  );
};

export { Card };
