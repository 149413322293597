import { useRef } from 'react';
import type { QuoteComponent } from '~/models/quote.model';
import { setTextColor, useBackground } from '~/utils/component-settings';

const Quote = (props: QuoteComponent) => {
  const refSection = useRef<HTMLQuoteElement | null>(null);
  const section = props.section;
  const textColor = setTextColor(section?.background?.text);
  useBackground(refSection, section?.background?.color, section?.background?.src);

  return (
    <blockquote ref={refSection} className={`content-view-blockquote ${textColor} text-lg md:text-xl`}>
      {props.richText && <div dangerouslySetInnerHTML={{ __html: props.richText }}></div>}
      {props.cite && (
        <cite className="text-base">
          <div dangerouslySetInnerHTML={{ __html: props.cite }}></div>
        </cite>
      )}
    </blockquote>
  );
};

export { Quote };
