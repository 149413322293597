import type { FeatureHighlightComponent, FeatureHighlightList, FeatureDetail } from '~/models/feature-highlight.model';
import type { Button as ButtonModel } from '~/models/button.model';
import type { MediaFile } from '~/models/media-file';
import { useRef } from 'react';
import { setTextColor, useBackground } from '~/utils/component-settings';
import { Button } from './commons/Button';
import { Icon } from './commons/Icon';
import { Carousel } from './commons/Carousel';

const FeatureHighlight = (props: FeatureHighlightComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);

  return (
    <div ref={refParent} data-layout="highlight" className="feature s-parent">
      <div
        className={`c-wrapper flex flex-col-reverse items-center justify-start mx-auto gap-8 md:gap-16 px-8 py-12 ${textColor}
          ${settings?.flip ? 'md:flex-row-reverse' : 'md:flex-row'}`}
      >
        <div className={`c-content-main md:w-1/2 ${props?.secondary?.media?.size === 'small' ? 'lg:w-7/12' : 'lg:w-5/12'}`}>
          {props.tagline && <div className="tagline text-xs mb-2">{props.tagline}</div>}
          {props.main?.title && <div className="title text-xl md:text-2xl font-bold mb-4">{props?.main.title}</div>}
          {props.main?.description && (
            <div className="text" dangerouslySetInnerHTML={{ __html: props.main?.description || '' }}></div>
          )}
          {props.main?.items && props.main.items.length > 0 && <List items={props.main.items} />}
          {props.buttons && props.buttons.length > 0 && (
            <div className="buttons mt-4 flex flex-row gap-2">
              {props.buttons?.map((button: ButtonModel, index: number) => (
                <Button key={index} type="link" variant={button.variant} size="md" to={button.url}>
                  {button.text}
                </Button>
              ))}
            </div>
          )}
        </div>
        <div className={`c-content-secondary layout-media relative w-full md:w-1/2 ${props?.secondary?.media?.size === 'small' ? 'lg:w-5/12': 'lg:w-7/12'}`}>
          {props.secondary?.media?.file?.url && (
            <Media file={props.secondary?.media.file} fallback={props.secondary?.media.fallback} />
          )}
          {(props.secondary?.media?.images) && (
            <Carousel arrows={false} dots={true} autoplay={false} className='w-full'>
            {props.secondary?.media?.images?.map((image, index) => (
              <Carousel.Slide key={index}>
                <div className="image w-full rounded-lg overflow-hidden">
                  <img
                    src={image.url}
                    alt={image.caption}
                    className="w-full object-cover object-center"
                  />
                </div>
                {image.caption && (
                  <div className="caption text-sm w-full md:w-5/6 pt-2 md:pt-3 px-2 h-8">{image.caption}</div>
                )}
              </Carousel.Slide>
            ))}
          </Carousel>
          )}
        </div>
      </div>
    </div>
  );
};

const Media = ({ file, fallback }: { file: MediaFile; fallback?: MediaFile }) => {
  const type = file.mime ? file.mime.split('/')[0] : undefined;

  return (
    <div className="c-content-media md:absolute h-full w-full overflow-hidden rounded-lg">
      {type === 'image' && (
        <div className="h-full w-full inline-block">
          <img src={file.url} alt={file.alt} className="object-center object-cover h-full w-full" />
        </div>
      )}

      {type === 'video' && (
        <video autoPlay loop muted playsInline className="w-full h-auto">
          <source src={file.url} type={file.mime} />
          {fallback?.url && <source src={fallback.url} type={file.mime} />}
          <meta itemProp="description" content={file.mime} />
          <p>Your browser does not support the video tag or the file format of this video.</p>
        </video>
      )}
    </div>
  );
};

const List = ({ description, items, title }: FeatureHighlightList) => {
  return (
    <div className="list py-4 flex flex-col gap-2">
      {title && <div className="title text-lg font-bold">{title}</div>}
      {description && <div className="text">{description}</div>}
      {items && items.length > 0 && (
        <ul className="flex flex-col gap-2">
          {items?.map((item: FeatureDetail, index: number) => (
            <li key={index} className="flex flex-row items-start gap-4 py-1.5">
              {item.icon?.name && (
                <span className="icon mt-0.5" aria-hidden="true">
                  <Icon type={item.icon.type} name={item.icon.name} />
                </span>
              )}
              {item.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { FeatureHighlight };
