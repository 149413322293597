import type { FeatureListsComponent, FeatureListsProps, FeatureDetail } from '~/models/feature-lists.model';
import type { Button as ButtonModel } from '~/models/button.model';
import { useRef } from 'react';
import { setTextColor, useBackground } from '~/utils/component-settings';
import { Button } from './commons/Button';
import { Icon } from './commons/Icon';

/*
  Style based on featureHighlights
*/
const FeatureLists = (props: FeatureListsComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);

  return (
    <div ref={refParent} data-layout="highlight" className="feature s-parent">
      <div
        className={`c-wrapper flex md:flex-row items-start flex-col justify-start mx-auto gap-8 md:gap-16 px-8 py-12 ${textColor}`}
      >
        <div className="c-content-main w-full">
          <div className="c-content-heading w-full md:w-9/12 mb-8">
            {props?.tagline && <div className="tagline text-sm mb-2">{props?.tagline}</div>}
            {props?.title && <div className="title text-2xl md:text-3xl font-bold mb-4">{props?.title}</div>}
            {props?.description && (
              <div className="text" dangerouslySetInnerHTML={{ __html: props?.description }}></div>
            )}
          </div>
          <div className="c-content-lists flex flex-col md:flex-row md:gap-8 lg:gap-16">
            {props.main?.items && props.main?.items.length > 0 && (
              <div className="w-full layout-list md:w-1/2">
                <List {...props?.main} />
              </div>
            )}
            {props.secondary?.items && props.secondary?.items.length > 0 && (
              <div className="w-full layout-list md:w-1/2">
                <List {...props?.secondary} />
              </div>
            )}
          </div>
          <div className="c-content-footer w-full mt-4">
            {props.buttons && props.buttons.length > 0 && (
              <div className="buttons mt-4 flex flex-row gap-2">
                {props.buttons?.map((button: ButtonModel, index: number) => (
                  <Button key={index} type="link" variant={button.variant} size="md" to={button.url}>
                    {button.text}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

const List = ({ title, items }: FeatureListsProps) => {
  return (
    <div className="list pb-4 flex flex-col gap-2">
      {title && <div className="title text-lg font-bold">{title}</div>}
      {items && items.length > 0 && (
        <ul className="flex flex-col gap-2 md:mt-2">
          {items?.map((item: FeatureDetail, index: number) => (
            <li key={index} className="flex flex-row items-start gap-4 py-1.5">
              {item.icon?.name && (
                <span className="icon mt-0.5" aria-hidden="true">
                  <Icon type={item.icon.type} name={item.icon.name} />
                </span>
              )}
              {item.text}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export { FeatureLists };
