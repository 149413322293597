import type { Feature } from '~/models/feature-set.model';
import type { Link as LinkModel } from '~/models/link.model';
import { Button } from '../commons/Button';
import { Icon } from '../commons/Icon';
import { useBackground } from '~/utils/component-settings';
import { useRef } from 'react';

type FeatureCardsProp = {
  content: Feature[];
};

type CardProp = {
  link?: LinkModel;
  title?: string;
  description?: string;
  image?: string;
};

const FeatureCards = ({ content }: FeatureCardsProp) => {

  return (
    <div className="grid md:grid-cols-3 gap-4">
      {content?.map((feature: Feature, index: number) => (
        <Card
          key={index}
          link={{ url: feature.linkUrl, text: feature.linkText, type: 'link' }}
          description={feature.descriptionRichText}
          title={feature.title}
          image={feature.imageUrl}
        />
      ))}
    </div>
  );
};

const Card = (card: CardProp) => {
  const refCard = useRef<HTMLDivElement | null>(null);
  useBackground(refCard, undefined, card?.image);

  return (
    <div ref={refCard} className={`feature-card-container ${card.image ? 'card-image' : 'card-solid'} overflow rounded-lg md:hover:shadow-lg transition-150 md:hover:-translate-y-1`}>
      <Button to={card.link?.url} type="link" variant='none' size="none" className='font-normal'>
        <div className="card flex flex-col justify-between p-4 h-full bg-white">
          <div className="flex flex-col">
            <div className="card-title font-bold text-lg mb-1.5">{card.title}</div>
            <div className="card-description" dangerouslySetInnerHTML={{ __html: card.description || '' }}></div>
          </div>
          {card?.link?.text && card?.link?.url && (
            <div className="card-button mt-6"><strong>{card?.link?.text}</strong> <Icon type='solid' name='chevron-right' className='icon ml-1 text-sm' /></div>
          )}
        </div>
      </Button>
    </div>
  );
};

export default FeatureCards;
