import type { NavigationHighlight } from '~/models/navigation.model';
import { Button } from '~/components/commons/Button';

type NavFeaturedProps = {
  data: NavigationHighlight;
};

/**
 * NavFeatured react component
 * @returns {any} NavFeatured React component
 */
const NavFeatured = ({ data }: NavFeaturedProps) => {
  let LAYOUT;

  switch (data.layout) {
    case 'large':
      LAYOUT = {
        wrapper: 'md:flex-col lg:flex-row-reverse lg:pl-8',
        image: 'h-full w-2/5 md:w-full 2xl:max-h-64',
        text: 'items-start w-3/5 px-4 py-4 md:px-8 md:w-full lg:pl-0 lg:pr-8 lg:py-8 xl:py-0',
        button: 'pt-8',
      };
      break;
    default:
      LAYOUT = {
        wrapper: 'flex-col py-12 px-4 lg:px-8 lg:py-4 xl:px-16 2xl:py-8',
        image: 'h-32 w-full md:h-full md:max-h-56 xl:h-32',
        text: 'items-center text-center',
        button: 'pt-4',
      };
      break;
  }

  return (
    <div className={`w-full row-span-2 h-full flex ${LAYOUT.wrapper} justify-center items-center`}>
      {data.imageUrl && (
        <div className={`${LAYOUT.image} bg-primary/30 overflow-hidden`}>
          <img src={data.imageUrl} alt={data.title} className="h-full w-full object-cover object-center" />
        </div>
      )}
      <div className={`flex flex-col justify-center ${LAYOUT.text}`}>
        <div className="text-lg lg:text-xl pt-2">{data.title}</div>
        <div className="text-sm lg:text-base">{data.description}</div>
        {data.button && (
          <div className={LAYOUT.button}>
            <Button type="link" variant="solid" size="md" to={data.button.url}>
              {data.button.text}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};

export { NavFeatured };
