import { useState } from 'react';
import { Icon } from './commons/Icon';
import { useLocation } from '@remix-run/react';
import { getLocaleFromPath } from '~/utils/locale';

const tableData = {
  headers: [
    '',
    { en: 'Standard', pt: 'Padrão', es: 'Estándar' },
    { en: 'Plus', pt: 'Mais', es: 'Plus' },
    { en: 'Premium', pt: 'Premium', es: 'Premium' },
  ],
  rows: [
    {
      id: 1,
      section: false,
      feature: {
        en: {
          label: 'Amount',
        },
        pt: {
          label: 'Amount2',
        },
        es: {
          label: 'Amount3',
        },
      },
      description: {
        en: 'In Lorem aute ad proident do esse.',
        pt: 'In Lorem aute ad proident do esse.',
        es: 'In Lorem aute ad proident do esse.',
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: false,
        text: { en: '+ 25% license fee', pt: '+ 25% license fee', es: '+ 25% license fee' },
      },
      premium: {
        checkmark: false,
        text: { en: '+ 50% license fee', pt: '+ 50% license fee', es: '+ 50% license fee' },
      },
    },
    {
      id: 2,
      section: false,
      feature: {
        en: {
          label: 'Client Manager',
        },
        pt: {
          label: 'Gestor de Cliente',
        },
        es: {
          label: 'Gestor de Cliente',
        },
      },
      standard: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: '+ 25% license fee', pt: '+ 25% license fee', es: '+ 25% license fee' },
      },
      premium: {
        checkmark: true,
        text: { en: '+ 50% license fee', pt: '+ 50% license fee', es: '+ 50% license fee' },
      },
    },
    {
      id: 3,
      section: true,
      feature: {
        en: {
          label: 'Support',
          description: null,
        },
        pt: {
          label: 'Support',
          description: null,
        },
        es: {
          label: 'Support',
          description: null,
        },
      },
      standard: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 4,
      section: false,
      feature: {
        en: {
          label: 'New Features',
        },
        pt: {
          label: 'New Features',
        },
        es: {
          label: 'New Features',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 5,
      section: false,
      feature: {
        en: {
          label: 'Functional issues/questions',
        },
        pt: {
          label: 'Functional issues/questions',
        },
        es: {
          label: 'Functional issues/questions',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 6,
      section: false,
      feature: {
        en: {
          label: 'Technical questions',
        },
        pt: {
          label: 'Technical questions',
        },
        es: {
          label: 'Technical questions',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 7,
      section: false,
      feature: {
        en: {
          label: 'Priority Support',
        },
        pt: {
          label: 'Priority Support',
        },
        es: {
          label: 'Priority Support',
        },
      },
      standard: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 8,
      section: false,
      feature: {
        en: {
          label: 'Support Channel',
        },
        pt: {
          label: 'Support Channel',
        },
        es: {
          label: 'Support Channel',
        },
      },
      standard: {
        checkmark: false,
        text: { en: 'App + Email', pt: 'App + Email', es: 'App + Email' },
      },
      plus: {
        checkmark: false,
        text: { en: 'App + Email', pt: 'App + Email', es: 'App + Email' },
      },
      premium: {
        checkmark: false,
        text: { en: 'App + Email + Phone', pt: 'App + Email + Phone', es: 'App + Email + Phone' },
      },
    },
    {
      id: 9,
      section: false,
      feature: {
        en: {
          label: 'Tickets per month',
        },
        pt: {
          label: 'Tickets per month',
        },
        es: {
          label: 'Tickets per month',
        },
      },
      standard: {
        checkmark: false,
        text: { en: 'Up to 4', pt: 'Up to 4', es: 'Up to 4' },
      },
      plus: {
        checkmark: false,
        text: { en: 'Unlimited', pt: 'Unlimited', es: 'Unlimited' },
      },
      premium: {
        checkmark: false,
        text: { en: 'Unlimited', pt: 'Unlimited', es: 'Unlimited' },
      },
    },
    {
      id: 10,
      section: false,
      feature: {
        en: {
          label: 'Online tickets monitoring',
        },
        pt: {
          label: 'Online tickets monitoring',
        },
        es: {
          label: 'Online tickets monitoring',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 11,
      section: false,
      feature: {
        en: {
          label: 'Supported profiles',
        },
        pt: {
          label: 'Perfis suportados',
        },
        es: {
          label: 'Perfiles admitidos',
        },
      },
      standard: {
        checkmark: false,
        text: { en: 'Portal Admins', pt: 'Portal Admin', es: 'Portal Admin' },
      },
      plus: {
        checkmark: false,
        text: { en: 'Scope Admins', pt: 'Area Admins', es: 'Area admin' },
      },
      premium: {
        checkmark: false,
        text: { en: 'All admins', pt: 'Todos admins', es: 'Todos admins' },
      },
    },
    {
      id: 12,
      section: false,
      feature: {
        en: {
          label: 'Estimated response time',
        },
        pt: {
          label: 'Estimated response time',
        },
        es: {
          label: 'Estimated response time',
        },
      },
      standard: {
        checkmark: false,
        text: { en: 'Avg. 24 working hours', pt: 'Avg. 24 working hours', es: 'Avg. 24 working hours' },
      },
      plus: {
        checkmark: false,
        text: { en: 'Avg. 16 working hours', pt: 'Avg. 16 working hours', es: 'Avg. 16 working hours' },
      },
      premium: {
        checkmark: false,
        text: { en: 'Avg. 8 working hours', pt: 'Avg. 8 working hours', es: 'Avg. 8 working hours' },
      },
    },
    {
      id: 13,
      section: false,
      feature: {
        en: {
          label: 'Estimated resolution time',
        },
        pt: {
          label: 'Estimated resolution time',
        },
        es: {
          label: 'Estimated resolution time',
        },
      },
      standard: {
        checkmark: false,
        text: { en: 'Avg. 40 working hours', pt: 'Avg. 40 working hours', es: 'Avg. 40 working hours' },
      },
      plus: {
        checkmark: false,
        text: { en: 'Avg. 32 working hours', pt: 'Avg. 32 working hours', es: 'Avg. 32 working hours' },
      },
      premium: {
        checkmark: false,
        text: { en: 'Avg. 24 working hours', pt: 'Avg. 24 working hours', es: 'Avg. 24 working hours' },
      },
    },
    {
      id: 14,
      section: true,
      feature: {
        en: {
          label: 'Training',
          description: null,
        },
        pt: {
          label: 'Training',
          description: null,
        },
        es: {
          label: 'Training',
          description: null,
        },
      },
      standard: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 15,
      section: false,
      feature: {
        en: {
          label: 'Dedicated sessions 1:1 (updates)',
        },
        pt: {
          label: 'Dedicated sessions 1:1 (updates)',
        },
        es: {
          label: 'Dedicated sessions 1:1 (updates)',
        },
      },
      standard: {
        checkmark: false,
        text: { en: 'Up to 1 /year', pt: 'Up to 1 /year', es: 'Up to 1 /year' },
      },
      plus: {
        checkmark: false,
        text: { en: 'Up to 2 /year', pt: 'Up to 2 /year', es: 'Up to 2 /year' },
      },
      premium: {
        checkmark: false,
        text: { en: 'Up to 4 /year', pt: 'Up to 4 /year', es: 'Up to 4 /year' },
      },
    },
    {
      id: 16,
      section: false,
      feature: {
        en: {
          label: 'Joint sessions',
        },
        pt: {
          label: 'Joint sessions',
        },
        es: {
          label: 'Joint sessions',
        },
      },
      standard: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: false,
        text: { en: 'Up to 2 /year', pt: 'Up to 2 /year', es: 'Up to 2 /year' },
      },
    },
    {
      id: 17,
      section: true,
      feature: {
        en: {
          label: 'Materials',
          description: null,
        },
        pt: {
          label: 'Materials',
          description: null,
        },
        es: {
          label: 'Materials',
          description: null,
        },
      },
      standard: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 18,
      section: false,
      feature: {
        en: {
          label: 'How-to-videos',
        },
        pt: {
          label: 'How-to-videos',
        },
        es: {
          label: 'How-to-videos',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 19,
      section: false,
      feature: {
        en: {
          label: 'Release notes',
        },
        pt: {
          label: 'Release notes',
        },
        es: {
          label: 'Release notes',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 20,
      section: true,
      feature: {
        en: {
          label: 'Other services',
          description: null,
        },
        pt: {
          label: 'Other services',
          description: null,
        },
        es: {
          label: 'Other services',
          description: null,
        },
      },
      standard: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 21,
      section: false,
      feature: {
        en: {
          label: 'Custom URL',
        },
        pt: {
          label: 'Custom URL',
        },
        es: {
          label: 'Custom URL',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 22,
      section: false,
      feature: {
        en: {
          label: 'Custom Email',
        },
        pt: {
          label: 'Custom Email',
        },
        es: {
          label: 'Custom Email',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 23,
      section: false,
      feature: {
        en: {
          label: 'Cloud hosting',
        },
        pt: {
          label: 'Cloud hosting',
        },
        es: {
          label: 'Cloud hosting',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 24,
      section: false,
      feature: {
        en: {
          label: 'Storage',
        },
        pt: {
          label: 'Storage',
        },
        es: {
          label: 'Storage',
        },
      },
      standard: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
    {
      id: 25,
      section: false,
      feature: {
        en: {
          label: 'Test environment',
        },
        pt: {
          label: 'Test environment2',
        },
        es: {
          label: 'Test environment3',
        },
      },
      standard: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      plus: {
        checkmark: false,
        text: { en: null, pt: null, es: null },
      },
      premium: {
        checkmark: true,
        text: { en: null, pt: null, es: null },
      },
    },
  ],
};

export const DsSupportTable = () => {
  const location = useLocation();
  const locale = getLocaleFromPath(location.pathname);

  const getTranslation = (obj: any) => {
    return obj[locale] || obj.en;
  };

  return (
    <div className="features-table">
      <div className="c-wrapper flex justify-center">
        <table className="max-w-screen-xl">
          <thead>
            <tr>
              {tableData?.headers?.map((header, index) => (
                <th key={index} scope="col" className={`${index === 0 ? '' : 'bg-primary text-white'}`}>
                  {index === 0 ? '' : getTranslation(header)}
                </th>
              ))}
            </tr>
          </thead>

          <tbody>
            {tableData?.rows?.map((row) => (
              <tr key={row.id} className={`${row?.section ? 'is-section' : ''}`}>
                <td data-label="" data-description={getTranslation(row?.feature).description ?? ''}>
                  {getTranslation(row?.feature).label}
                  {getTranslation(row?.feature).description ? (
                    <>
                      <p className="description">{getTranslation(row?.feature).description}</p>
                      <Tooltip content={getTranslation(row?.feature).description} />{' '}
                    </>
                  ) : (
                    ''
                  )}
                </td>
                <td
                  data-label={getTranslation(tableData?.headers[1])}
                  className={`${row?.standard?.checkmark ? 'has-checkmark' : ''}`}
                >
                  {row?.standard?.checkmark ? <CheckMark /> : getTranslation(row?.standard?.text)}
                </td>
                <td
                  data-label={getTranslation(tableData?.headers[2])}
                  className={`${row?.plus?.checkmark ? 'has-checkmark' : ''}`}
                >
                  {row?.plus?.checkmark ? <CheckMark /> : getTranslation(row?.plus?.text)}
                </td>
                <td
                  data-label={getTranslation(tableData?.headers[3])}
                  className={`${row?.premium?.checkmark ? 'has-checkmark' : ''}`}
                >
                  {row?.premium?.checkmark ? <CheckMark /> : getTranslation(row?.premium?.text)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

const CheckMark = () => {
  return (
    <span className="icon" aria-hidden="true">
      <Icon type="solid" name="check" />
      <span className="sr-only">Check</span>
    </span>
  );
};

const Tooltip = ({ content }: any) => {
  const [showTooltip, setShowTooltip] = useState(false);

  const handleMouseEnter = () => setShowTooltip(true);
  const handleMouseLeave = () => setShowTooltip(false);
  const handleKeyDown = (e: any) => {
    if (e.key === 'Escape') {
      setShowTooltip(false);
    }
  };

  return (
    <div
      className="tooltip relative ml-1"
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      onFocus={handleMouseEnter}
      onBlur={handleMouseLeave}
      onKeyDown={handleKeyDown}
    >
      <button type="button" aria-label="More">
        <Icon type="regular" name="circle-question" className="tooltip-icon text-xs" />
        <span className="sr-only">Tooltip</span>
      </button>
      {showTooltip && (
        <div className="absolute bg-white outline outline-1 outline-primary-200 text-sm shadow-lg px-2 py-1 w-max max-w-md text-neutral-600 flex rounded-sm z-50 bottom-full mb-0.5 pointer-events-none">
          {content}
        </div>
      )}
    </div>
  );
};
