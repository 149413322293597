import type { ListItem } from '~/models/list-item.model';
import type { RelatedContentComponent } from '~/models/related-content.model';
import { Card } from '../commons/Card';

const RelatedContent = ({ articles, resources, relatedArticlesTitle, relatedResourcesTitle }: RelatedContentComponent) => {
  const oneColumn = !resources?.length || !articles?.length;
  return (
    <>
      {
        ((articles && articles?.length) || (resources && resources.length > 0)) &&
        <div className={`related rounded-lg overflow-hidden grid ${oneColumn ? 'md:grid-cols-1 my-0 mx-auto' : 'md:grid-cols-2'}`}>
          {
            articles && articles?.length > 0 &&
            <div className="related-articles p-6 pt-8 max-w-screen-md">
              {relatedArticlesTitle && <div className="title tagline mb-4">{relatedArticlesTitle}</div>}
              <div className="related-articles-cards flex flex-col gap-4">
                {articles?.map((card: ListItem, index: number) => (
                  <Card key={index} {...card} />
                ))}
              </div>
            </div>
          }
          {
            resources && resources.length > 0 &&
            <div className="related-resources p-6 pt-8 max-w-screen-md">
              {relatedResourcesTitle && <div className="title tagline mb-4">{relatedResourcesTitle}</div>}
              <div className="related-resources-cards flex flex-col gap-4">
                {resources?.map((card: ListItem, index: number) => (
                  <Card key={index} {...card} />
                ))}
              </div>
            </div>
          }
        </div>
      }
    </>
  );
};

export { RelatedContent };
