import React, { Fragment } from 'react';

type SelectProps = {
  name?: string;
  id?: string;
  className?: string;
  value?: string | number;
  defaultValue?: string | number;
  onChange?: (e?: any) => void;
  disabled?: boolean;
  children: any;
};

type OptionProps = {
  value?: string | number;
  disabled?: boolean;
  children?: any;
};

const Select = ({ name, id, defaultValue = '', className = '', value, onChange, disabled = false, children, ...more }: SelectProps) => {
  const options = React.Children.map(children, (child) => (child?.type?.displayName === 'Options' ? child : null));

  const classDisabled = disabled ? 'opacity-50 pointer-events-none' : '';
  const baseStyle = 'select-input';

  let classBase = [baseStyle, classDisabled];

  if (className) {
    classBase = [...classBase, ...className.split(' ')];
  }

  return (
    <>
      <select value={value} defaultValue={defaultValue} onChange={onChange} id={id} name={name} className={classBase.join(' ')} {...more}>
        {options?.map((option: any, index: number) => (
          <Fragment key={index}>{option}</Fragment>
        ))}
      </select>
    </>
  );
};

const Options = ({ children }: any) => children;
Options.displayName = 'Options';
Select.Options = Options;

const Option = ({ value = '', children, disabled }: OptionProps) => {
  return (
    <>
      <option value={value} disabled={disabled}>
        {children}
      </option>
    </>
  );
};
Option.displayName = 'Option';
Select.Option = Option;

export { Select };
