import { useEffect, useState } from 'react';
import { Icon } from '../commons/Icon';

export type ShareProps = {
  title?: string;
  imageUrl?: string;
  locale?: 'en' | 'es' | 'pt' | string;
  atHeader?: boolean;
};

const ShareArticle = (props: ShareProps) => {
  const [url, setUrl] = useState<string>('');
  let shareText: string;

  useEffect(() => {
    setUrl(document.location.origin + document.location.pathname);
  }, []);

  switch (props.locale) {
    case 'pt':
      shareText = 'Partilhe este artigo';
      break;
    case 'es':
      shareText = 'Comparte este artículo';
      break;
    default:
      shareText = 'Share this article';
      break;
  }

  return (
    <div className={`share-article hidden md:flex flex-row w-full ${props.atHeader ? 'justify-center pt-2' : 'justify-start pt-16 pb-8'} items-center gap-4`}>
      <div className="share-text">{shareText}</div>
      <div className="share-icons flex flex-row gap-4">
        <div className="share-social">
          <a
            href={
              'https://www.linkedin.com/shareArticle?mini=true&url=' + url + '&title=' + props.title + '&source=' + url
            }
            className="social-item"
            rel="noreferrer"
            target="_blank"
          >
            <Icon type="brands" name="linkedin" />
          </a>
        </div>
        <div className="share-social">
          <a href={'http://www.facebook.com/share.php?u=' + url} className="social-item" rel="noreferrer" target="_blank">
            <Icon type="brands" name="square-facebook" />
          </a>
        </div>
        <div className="share-social">
          <a
            href={'https://twitter.com/intent/tweet?text=' + props.title + ' ' + url + '&original_referer=' + url}
            className="social-item"
            rel="noreferrer"
            target="_blank"
          >
            <Icon type="brands" name="square-x-twitter" />
          </a>
        </div>
      </div>
    </div>
  );
};

export { ShareArticle };
