import { useRef } from 'react';
import type { TestimonialCarrousselComponent } from '~/models/testimonial-carroussel.model';
import { Carousel } from './commons/Carousel';
import Image from './commons/Image';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';

const TestimonialCarroussel = (props: TestimonialCarrousselComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const section = props.section;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  const layout = setLayoutSwitch('testimonials', settings?.layout);

  return (
    <div ref={refParent} data-layout={layout} className={`testimonials s-parent ${textColor}`}>
      <div className="c-wrapper px-8 py-8 md:py-24 w-full mx-auto max-w-screen-2xl">
        {(section?.tagline || section?.title || section?.description) && (
        <div className="c-text w-full mb-2 md:mb-6">
          <div className="texts flex flex-col gap-2 w-full md:w-9/12">
            {section?.tagline && <div className="tagline">{section?.tagline}</div>}
            {section?.title && <div className="title text-2xl md:text-3xl font-bold">{section?.title}</div>}
            {section?.description && <div className="description text-lg pb-8 md:pb-0 mt-4" dangerouslySetInnerHTML={{ __html: section?.description }}></div>}
          </div>
        </div>
        )}
        <div className="c-container">
          <Carousel
            arrows={settings?.carroussel?.arrows}
            dots={settings?.carroussel?.dots}
            autoplay={settings?.carroussel?.autoplay}
          >
            {props.testimonials?.map((testimonial, index) => (
              <Carousel.Slide key={index}>
                <div className="c-content flex md:justify-center md:items-center">
                  {testimonial?.imageUrl && (
                    <div className="image">
                      {settings?.layout === 'basic' ? (
                        <Image
                          src={testimonial.imageUrl}
                          alt={testimonial.name}
                          grayscale={settings.effects === 'grayscale'}
                        />
                      ) : (
                        <Image
                          src={testimonial.imageUrl}
                          alt={testimonial.name}
                          grayscale={settings?.effects === 'grayscale'}
                        />
                      )}
                    </div>
                  )}
                  <div className="texts flex flex-col gap-4">
                    {/* <div className="quote">{testimonial.text}</div> */}
                    {/* TODO: replace with only the richText */}
                    {testimonial.richText ? (
                      <div className="quote" dangerouslySetInnerHTML={{ __html: testimonial.richText }}></div>
                    ) : (
                      <div className="quote quotation-marks">{testimonial.text}</div>
                    )}
                    <div className="author md:text-lg">
                      <span className="name">{testimonial.name}</span>
                      <span className="position">{testimonial.company}</span>
                    </div>
                  </div>
                </div>
              </Carousel.Slide>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export { TestimonialCarroussel };
