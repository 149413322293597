import type { StackedComponent } from '~/models/stacked.model';
import { useRef } from 'react';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';
import { StackedList } from './stacked/_layouts';
import { PaginationInput } from './commons/PaginationInput';

const Stacked = ({ settings, section, items }: StackedComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  const layout = setLayoutSwitch('stacked', settings?.layout);

  return (
    <div ref={refParent} data-layout={layout} className="feed s-parent">
      <div className={`c-wrapper flex flex-col w-full mx-auto gap-8 px-8 py-12 ${textColor}`}>
        <div className="c-header flex flex-col md:flex-row gap-4">
          <div className="texts flex flex-col gap-2 w-full md:w-9/12">
            {section?.tagline && <div className="tagline text-sm">{section.tagline}</div>}
            {section?.title && <div className="title text-2xl md:text-3xl font-bold">{section.title}</div>}
            {section?.description && (
              <div className="description text-lg mt-2" dangerouslySetInnerHTML={{ __html: section.description }}></div>
            )}
          </div>
        </div>
        <div className="c-content">
          <StackedList list={items} layout={settings?.layout} />
        </div>
        <div className="c-pagination mt-8">
          <PaginationInput total={0} page={1} totalPages={8} hitsPerPage={4} />
        </div>
      </div>
    </div>
  );
};

export { Stacked };
