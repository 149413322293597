import type { FeatureSetComponent } from '~/models/feature-set.model';
import { useRef } from 'react';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';
import { Button } from './commons/Button';
import { FeatureGrid, FeatureCards, FeatureColumns, FeatureTabs } from './feature/_layouts';
import type { Button as ButtonModel } from '../models/button.model';

const FeatureSet = (props: FeatureSetComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const section = props.section;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  const layout = setLayoutSwitch('feature', settings?.layout || 'tabs');

  let showButtons;

  switch (layout) {
    case 'grid':
    case 'columns': {
      showButtons = 'bottom';
      break;
    }
    default: {
      showButtons = 'top';
      break;
    }
  }

  return (
    <div
      ref={refParent}
      data-layout={layout}
      data-tab-view={layout === 'tabs' && settings?.tab ? settings?.tab : ''}
      className="feature s-parent"
    >
      <div className={`c-wrapper flex flex-col mx-auto gap-4 md:gap-8 px-8 py-8 md:py-16 ${textColor}`}>
        <div className="c-text w-full flex flex-col md:flex-row gap-8 pb-8 mb:pb-0 justify-between">
          <div className="texts flex flex-col gap-2 md:gap-4 w-full md:w-9/12">
            <h2
              className="title text-2xl md:text-3xl font-bold"
              dangerouslySetInnerHTML={{ __html: section?.title || '' }}
            ></h2>
            {section?.description && <div className="description text-lg">{section?.description}</div>}
          </div>
          {showButtons === 'top' && (
            <div className="context flex flex-row md:justify-end items-center">
              <SectionButtons buttons={section?.buttons || []} />
            </div>
          )}
        </div>
        <div className="c-content">
          {layout === 'grid' && <FeatureGrid content={props.features} />}
          {layout === 'cards' && <FeatureCards content={props.features} />}
          {layout === 'columns' && <FeatureColumns content={props.features} />}
          {layout === 'tabs' && (
            <FeatureTabs content={{ content: props.features, settings: { tab: settings?.tab || 'vertical' } }} />
          )}
        </div>
        {showButtons === 'bottom' && (
          <div className="c-bottom">
            <SectionButtons buttons={section?.buttons || []} />
          </div>
        )}
      </div>
    </div>
  );
};

const SectionButtons = ({ buttons }: { buttons: ButtonModel[] }) => {
  return (
    <div className="context flex flex-row">
      {buttons.length > 0 && (
        <div className="buttons flex flex-row gap-2">
          {buttons?.map((button: ButtonModel, index: number) => (
            <Button key={index} type="link" variant={button.variant} size="md" to={button.url}>
              {button.text}
            </Button>
          ))}
        </div>
      )}
    </div>
  );
};

export { FeatureSet };
