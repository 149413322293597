import { Link, useLocation, Form, useNavigate } from '@remix-run/react';
import { useState, useEffect, useCallback } from 'react';
import type { FormEvent, ReactNode } from 'react';
import { BREAKPOINT } from '~/constants/Theme';
import { Button } from '~/components/commons/Button';
import { Input } from '~/components/commons/Input';
import NavItem from './nav/NavItem';
import type { Navigation } from '~/models/navigation.model';
import { LocalePicker } from './locale-picker';
import windowSize from '~/hooks/useWindowSize';
import { Icon } from '../commons/Icon';
import type { Meta } from '~/models/metadata';
import { getLocaleFromPath } from '~/utils/locale';

/**
 * Nav React component
 * @returns Nav React component
 */
const Nav = (props: { navigation: Navigation; meta: Meta }) => {
  const location = useLocation();
  const locale = getLocaleFromPath(location.pathname);
  const [burger, setBurger] = useState(false);
  const [search, setSearch] = useState(false);
  const [searchAnimate, setSearchAnimate] = useState(false);
  const [currentLocationKey, setCurrentLocationKey] = useState(location.key);

  windowSize();

  function addBodyMargin() {
    const body = document.getElementsByTagName('body')[0];
    if (!body) return;
    body.classList.add('has-nav');
  }

  const handleBurger = () => {
    setBurger(!burger);
    addEventListener('resize', () => {
      if (window.innerWidth > BREAKPOINT.lg) {
        setBurger(false);
      }
    });
  };

  const focusSearchInput = useCallback(() => {
    const searchInput = document.getElementById('nav-search-input') as HTMLInputElement;
    if (search) {
      searchInput.focus();
    }
  }, [search]);

  const handleSearchInput = useCallback((options?: { close?: boolean }) => {
    if (!search && !options?.close) {
      setSearch(true);
      setTimeout(() => {
        setSearchAnimate(true);
        focusSearchInput();
      }, 100); // Delay in
    } else {
      setSearchAnimate(false);
      setTimeout(() => {
        setSearch(false);
      }, 500); // Delay out
    }
  }, [search, setSearch, setSearchAnimate, focusSearchInput]);

  const handleEsc = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        handleSearchInput();
      }
    },
    [handleSearchInput],
  );

  useEffect(() => {
    addBodyMargin();

    if (currentLocationKey !== location.key) {
      handleSearchInput({ close: true });
      setCurrentLocationKey(location.key);
    }

    if (search) {
      focusSearchInput();

      window.addEventListener('keydown', handleEsc);
      return () => {
        window.removeEventListener('keydown', handleEsc);
      };
    }

  }, [search, currentLocationKey, location, focusSearchInput, handleEsc, handleSearchInput]);

  /*
   * Close burger menu on route change
   */
  useEffect(() => {
    if (window.innerWidth < BREAKPOINT.lg) {
      setBurger(false);
    }
  }, [location]);

  let align, localeSearchPlaceholder;
  switch (props.navigation?.align) {
    case 'left': {
      align = 'lg:justify-start';
      break;
    }
    case 'center': {
      align = 'lg:justify-center';
      break;
    }
    default: {
      align = 'lg:justify-end';
      break;
    }
  }

  switch (locale) {
    case 'pt':
      localeSearchPlaceholder = 'Pesquisa...';
      break;
    case 'es':
      localeSearchPlaceholder = 'Pesquisa...';
      break;
    default:
      localeSearchPlaceholder = 'Search...';
      break;
  }

  return (
    <nav className="fixed w-full flex top-0 h-16 md:h-20 px-4 md:px-8 py-4 z-40">
      <div className="c-wrapper w-full flex flex-row justify-between items-center gap-8">
        <div className="w-44 md:w-48 flex flex-shrink-0 transition-300">
          <Link to="/" title={props.meta?.title}>
            <img src={props.meta?.websiteLogoUrl} width={201} height={38} alt={props.meta?.title} />
          </Link>
        </div>
        <div
          className={`nav-items transition-300 w-full ${
            burger ? 'block' : 'hidden'
          } absolute lg:static lg:block top-16 md:top-20 left-0 overflow-y-auto lg:overflow-y-visible bg-white lg:bg-transparent`}
        >
          <ul className={`flex flex-col lg:flex-row lg:gap-2 ${align}`}>
            {props.navigation?.navigationItems?.map((item, index) => {
              return <NavItem item={item} key={index} />;
            })}
          </ul>
          {/* Mobile only */}
          {burger && (
            <>
              <div className="inline-flex px-4 pt-8 w-full justify-center lg:hidden">
                {props.navigation?.button?.url && props.navigation?.button?.text && (
                  <Button
                    type="link"
                    size="lg"
                    to={props.navigation.button?.url}
                    className="w-full sm:w-1/2 text-center"
                  >
                    {props.navigation.button?.text}
                  </Button>
                )}
              </div>
              <SearchForm>
                <div className="flex flex-row p-4 border-t border-primary/20 mt-8">
                  <Input
                    id="mobile-nav-search-input"
                    name="q"
                    placeholder={localeSearchPlaceholder}
                    type="search"
                  />
                </div>
              </SearchForm>
              <div className="flex flex-row p-4 mb-8 border-t border-primary/20">
                <LocalePicker />
              </div>
            </>
          )}
        </div>
        <div className="lg:min-w-fit flex flex-row items-center gap-4">
          {props.navigation?.button?.url && props.navigation?.button?.text && (
            <Button type="link" size="sm" to={props.navigation.button?.url} className="hidden lg:block">
              {props.navigation.button?.text}
            </Button>
          )}
          <Button
            aria-label="hamburger"
            variant="square"
            size="square"
            type="button"
            className="block lg:hidden"
            onClick={() => handleBurger()}
          >
            {burger ? (
              <Icon type="solid" name="minus" className="text-2xl px-1.5" />
            ) : (
              <Icon type="solid" name="bars" className="text-2xl px-1.5" />
            )}
          </Button>
           <Button
            aria-label="search"
            variant="none"
            size="square"
            type="button"
            className="search-button hidden lg:block lg:hover:text-primary"
            onClick={() => handleSearchInput()}
          >
            <Icon type="solid" name="search" className="text-lg px-1.5" />
          </Button>
          <LocalePicker className="hidden lg:block" />
        </div>
      </div>
      {/* Search bar */}
      {search && (
        <SearchForm>
          <div
            className={`absolute left-0 top-0 h-full w-full bg-white hidden lg:flex items-center transition-transform duration-500 ${
              searchAnimate ? 'translate-y-0' : '-translate-y-32'
            }`}
            aria-hidden={!search}
            aria-haspopup="true"
          >
            <div className="mx-auto max-w-screen-2xl h-full py-4 px-8 2xl:px-0 w-full flex items-center transition-all duration-500">
              <div className="field w-full flex flex-row gap-4">
                {/* search input */}
                <Input
                  id="nav-search-input"
                  name="q"
                  placeholder={localeSearchPlaceholder}
                  type="search"
                />
                <Button
                  aria-label="close search"
                  variant="none"
                  size="square"
                  type="button"
                  className="lg:hover:text-primary"
                  onClick={() => handleSearchInput()}
                >
                  <Icon type="solid" name="close" className="text-lg px-2" />
                </Button>
              </div>
            </div>
          </div>
        </SearchForm>
      )}
    </nav>
  );
};

/**
 * Renders Form component for search.
 * @param children - Render within the search form.
 * @returns The rendered search form component.
 */
type SearchFormProps = {
  children?: ReactNode;
}

const SearchForm = ({ children }: SearchFormProps) => {
  const navigate = useNavigate();

  /**
   * Handles the submission of the search query
   * @param query - The search query.
   * @returns The search results page.
   */
  const submitSearchQuery = useCallback((query?: string) => {
    const searchQuery = query ?? new URLSearchParams(location.search).get('q');

    if (!searchQuery) return;

    navigate(`/search?q=${encodeURI(searchQuery || '')}`);
  }, [navigate]);

  /**
   * Handles the submission of the search form.
   * @param event - The form event.
   * @returns The search results page.
   */
  const handleSearchFormSubmit = async (event: FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const formData = new FormData(event.currentTarget);
    const rawQuery = formData.get('q') as string;

    if (!rawQuery) return;

    // Remove extra whitespace from the search query
    let query = rawQuery.trim();
    query = query.replace(/\s+/g, ' ');

    if (!query) return;
    submitSearchQuery(query);
  };

  return (
    <Form
      method="post"
      action="/search"
      onSubmit={handleSearchFormSubmit}
    >
      {children}
    </Form>
  );
};

export { Nav };
