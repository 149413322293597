import type { ListItem } from '~/models/list-item.model';
import { Card } from '../commons/Card';

type FeedGridProps = {
  list?: ListItem[];
}

const FeedGrid = ({ list }: FeedGridProps) => {
  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
      {list?.map((card: ListItem, index: number) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};

export default FeedGrid;
