import { useState } from 'react';

type CheckboxProps = {
  name?: string;
  value?: boolean;
  required?: boolean;
  className?: string;
  onChange?: () => void;
};

function Checkbox({ name, value, className, required, onChange }: CheckboxProps) {
  const [checked, setChecked] = useState(false);

  const handleChange = () => {
    setChecked(!checked);
  };

  return (
    <>
      <input
        type="checkbox"
        id={name}
        name={name}
        checked={value}
        onChange={handleChange}
        className={className}
        required={required}
      />
    </>
  );
}

export { Checkbox };
