import type { ListItem } from '~/models/list-item.model';
import type { Highlights } from '~/models/highlights.model';
import type { Article } from '~/models/article.model';
import type { Link as LinkModel } from '~/models/link.model';
import { Link } from '@remix-run/react';
import { Card } from '../commons/Card';

type FeedSidebarProps = {
  list?: ListItem[];
  highlights?: Highlights;
};

const FeedSidebar = ({ list, highlights }: FeedSidebarProps) => {
  return (
    <div className="flex flex-col md:flex-row gap-16 md:gap-4">
      <div className="flex flex-col gap-4 w-full">
        {list?.map((card: ListItem, index: number) => (
          <Card key={index} {...card} />
        ))}
      </div>
      {highlights &&
        <div className="sidebar w-full md:w-[500px] flex flex-col gap-2">
          {highlights.title && <div className="sidebar-head tagline mb-2">{highlights.title}</div>}
          {highlights.article?.title &&
            <div className="sidebar-featured">
              <SidebarFeatured article={highlights.article} />
            </div>
          }
          {
            highlights.links && highlights.links?.length > 0 &&
            <div className="sidebar-list">
              <SidebarList list={highlights.links} />
            </div>
          }
        </div>
      }
    </div>
  );
};

type SidebarFeaturedProps = {
  article: Article;
}

const SidebarFeatured = ({ article }: SidebarFeaturedProps) => {
  const card = {
    category: article.category,
    title: article.title,
    description: article.description,
    url: article.url,
    imageUrl: article.mainImageUrl,
  };

  return <Card {...card} />;
};

type SidebarListProps = {
  list: LinkModel[];
}


const SidebarList = ({ list }: SidebarListProps) => {
  return (
    <>
      <ul className="list">
        {list?.map((item: LinkModel, index: number) => (
          <li key={index}>
            <Link to={item.url || '#'}>{item.text}</Link>
          </li>
        ))}
        {/* <li>
          <Link to="/recent">See all recent updates —</Link>
        </li> */}
      </ul>
    </>
  );
};

export default FeedSidebar;
