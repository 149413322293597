import { useState } from 'react';
import Image from '~/components/commons/Image';
import { Button } from '../commons/Button';
import { Icon } from '../commons/Icon';
import type { Link } from '~/models/link.model';
import type { Feature } from '~/models/feature-set.model';

type FeatureTabsProp = {
  content: {
    settings: {
      tab: 'vertical' | 'horizontal';
    };
    content: Feature[];
  };
};

type CardProp = {
  show?: boolean;
  tab?: string;
  title?: string;
  descriptionRichText?: string;
  link?: Link;
  image: {
    src?: string;
    alt?: string;
  };
};

const FeatureTabs = ({ content }: FeatureTabsProp) => {
  const [activeTab, setActiveTab] = useState<number>(0);
  const cards = content.content;
  const settings = content.settings;

  return (
    <div className="tabs flex flex-col gap-8">
      {settings.tab === 'horizontal' && (
        <div className="tabs-selector hidden md:flex flex-row justify-center">
          {cards?.map((item: Feature, index: number) => (
            <Button
              key={index}
              onClick={() => {
                setActiveTab(index);
              }}
              variant="none"
              className={`tabs-selector-item ${activeTab === index ? 'active' : ''}`}
            >
              {item.title}
            </Button>
          ))}
        </div>
      )}
      <div className="tabs-content grid md:grid-cols-2 xl:flex xl:flex-row gap-8 md:gap-4 mb-8">
        <div className="tabs-list flex flex-col gap-4 md:gap-0 xl:w-5/12">
          {cards?.map((card: Feature, index: number) => (
            <div key={index} className={`tab ${activeTab === index ? 'active' : ''}`}>
              {settings.tab === 'vertical' ? (
                <Button onClick={() => setActiveTab(index)} variant="none" className='font-normal'>
                  <CardList
                    title={card.title}
                    descriptionRichText={card.descriptionRichText}
                    image={{ src: card.imageUrl, alt: card.title }}
                    key={index}
                    link={{ text: card.linkText, url: card.linkUrl, type: 'link' }}
                    tab={settings.tab}
                    show={activeTab === index}
                  />
                </Button>
              ) : (
                <CardList
                  tab={settings.tab}
                  image={{ src: card.imageUrl, alt: card.title }}
                  descriptionRichText={card.descriptionRichText}
                  key={index}
                  link={{ text: card.linkText, url: card.linkUrl, type: 'link' }}
                  title={card.title}
                  show={activeTab === index}
                />
              )}
            </div>
          ))}
        </div>
        <div className="tabs-image hidden md:block xl:w-7/12">
          {cards?.map((card: Feature, index: number) => (
            <CardPreview
              key={index}
              show={activeTab === index}
              image={{ src: card.imageUrl, alt: card.title }}
              descriptionRichText={card.descriptionRichText}
              link={{ text: card.linkText, url: card.linkUrl, type: 'link' }}
              tab={settings.tab}
              title={card.title}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

const CardList = (card: CardProp) => {
  return (
    <div className="card flex flex-col gap-2">
      {card.image?.src && (
        <div className="card-thumbnail block md:hidden rounded-lg overflow-hidden">
          <Image
            src={card.image.src}
            alt={card.image.alt}
            className="h-full w-full aspect-image object-center object-cover"
          />
        </div>
      )}
      <div className="card-title font-bold text-lg">{card.title}</div>
      <div
        className="card-description md:text-sm"
        dangerouslySetInnerHTML={{ __html: card.descriptionRichText || '' }}
      ></div>
      {card.link?.url && (
        <div className="card-button text-sm mt-4">
          <Button
            to={card.link?.url}
            variant="text"
            type="link"
            size="sm"
            className="rounded-none flex flex-row items-center gap-1 w-fit"
          >
            {card.link?.text}
          </Button>
        </div>
      )}
    </div>
  );
};

const CardPreview = (card: CardProp) => {
  return (
    <>
      {card.show && (
        <div className="card-thumbnail block h-full w-full rounded-lg overflow-hidden">
          <div
            aria-label={card.image.alt}
            style={{ backgroundImage: `url(${card.image.src})` }}
            className="h-full w-full aspect-image bg-center bg-cover"
          />
        </div>
      )}
    </>
  );
};

export default FeatureTabs;
