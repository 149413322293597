import Image from '~/components/commons/Image';
import type { Feature } from '~/models/feature-set.model';
import type { Link } from '~/models/link.model';
import { Button } from '../commons/Button';

type FeatureColumnsProp = {
  content: Feature[];
};

type CardProp = {
  title?: string;
  excerpt?: string;
  link?: Link;
  image: {
    src?: string;
    alt?: string;
  };
};

const FeatureColumns = ({ content }: FeatureColumnsProp) => {
  let contentCols;
  const baseColsStyle = 'lg:max-w-screen-lg 2xl:max-w-screen-xl';

  switch (content.length) {
    case 3:
      contentCols = 'lg:grid-cols-3 ' + baseColsStyle;
      break;

    case 2:
      contentCols = baseColsStyle;
      break;

    default:
      contentCols = 'lg:grid-cols-4 xl:max-w-screen-2xl';
      break;
  }

  return (
    <div className={`grid sm:grid-cols-2 ${contentCols} gap-8 mb-8 md:mb-auto lg:mx-auto`}>
      {content?.map((card: Feature, index: number) => (
        <Card
          key={index}
          link={{ type: 'link', url: card.linkUrl, text: card.linkText }}
          title={card.title}
          excerpt={card.descriptionRichText}
          image={{ src: card.imageUrl, alt: card.title }}
        />
      ))}
    </div>
  );
};

const Card = (card: CardProp) => {
  return (
    <div className="card flex flex-col justify-between">
      <div className="card-group flex flex-col gap-2">
        {card.image.src && (
          <div className="card-thumbnail aspect-image rounded-sm overflow-hidden">
              <Image src={card.image.src} alt={card.image.alt} className="h-full w-full object-center object-cover" />
          </div>
        )}
        <div className="card-title text-lg font-bold">{card.title}</div>
        <div className="card-excerpt md:text-base" dangerouslySetInnerHTML={{ __html: card.excerpt || '' }}></div>
      </div>
      {card.link?.url && (
        <div className="card-button text-sm mt-4">
          <Button
            to={card.link?.url}
            variant="text"
            type="link"
            size="md"
            className="rounded-none flex flex-row items-center gap-1 w-fit"
          >
            {card.link?.text}
          </Button>
        </div>
      )}
    </div>
  );
};

export default FeatureColumns;
