import { useRef } from 'react';
import type { BusinessMetricsComponent, StatProps } from '~/models/business-metrics.model';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';

const BusinessMetrics = (props: BusinessMetricsComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  const layout = setLayoutSwitch('metrics', settings?.layout);

  return (
    <div ref={refParent} data-layout={layout} className="metrics s-parent">
      <div className="c-wrapper px-8 py-8 md:py-24 w-full mx-auto">
        <div className={`c-container flex flex-col gap-8 md:gap-16 ${textColor}`}>
          <div className="c-text w-full flex flex-row justify-between items-center">
            <div className="texts w-full flex flex-col md:flex-row gap-2">
              {props.title && (
                <h2 className="title md:w-1/3 2xl:w-1/2 text-2xl md:text-3xl font-bold">{props.title}</h2>
              )}
              {props.description && <div className="description md:w-2/3 2xl:w-1/2 text-lg">{props.description}</div>}
            </div>
          </div>
          <div className="c-stats grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {props.stats?.map((stat, index) => (
              <Stat key={index} title={stat.title} value={stat.value} />
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const Stat = (props: StatProps) => {
  return (
    <>
      <div className="stat flex flex-col justify-center items-start gap-1 rounded-lg p-8 border border-neutral-900">
        <div className="stat-value font-secondary -tracking-wide text-4xl md:text-5xl">{props.value}</div>
        <div className="stat-title text-lg md:text-xl">{props.title}</div>
      </div>
    </>
  );
};

export { BusinessMetrics };
