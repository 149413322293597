import { useEffect } from 'react';
import { LAYOUTS_NAMES } from '~/constants/Theme';

/**
 * Component Text Color
 * @param {string} settingsTextColor
 * @returns {string} Text Color
 */
function setTextColor(settingsTextColor?: string) {
  return settingsTextColor ? 'base-text-' + settingsTextColor : '';
}

/**
 * Component Layout Switch
 * @param {string} componentName
 * @param {string} settingsLayout
 * @returns {string} Layout Switch
 */
function setLayoutSwitch(componentName: string, settingsLayout?: string) {
  if (LAYOUTS_NAMES.hasOwnProperty(componentName)) {
    for (const i of LAYOUTS_NAMES[componentName]) {
      if (i.layout === settingsLayout) {
        return i.css;
      }
    }
  }
}

/**
 * Component Background Settings Hook
 * @param {any} ref Parent useRef()
 * @param {string} backgroundColor Background color
 * @param {string} backgroundSrc Background source
 * @returns Background Settings Hook
 */
function useBackground(ref: any, backgroundColor?: string, backgroundSrc?: string) {
  useEffect(() => {
    if (!ref.current) return;

    if (ref.current && backgroundSrc) {
      ref.current.style.backgroundImage = `url(${backgroundSrc})`;
      ref.current.style.backgroundColor = 'transparent';
    } else if (ref.current && backgroundColor) {
      ref.current.style.backgroundImage = 'none';
      ref.current.style.backgroundColor = backgroundColor;
    } else {
      ref.current.style.backgroundImage = 'none';
      ref.current.style.backgroundColor = 'transparent';
    }
  }, [ref, backgroundColor, backgroundSrc]);
}

export { setTextColor, setLayoutSwitch, useBackground };
