import { useCallback, useState } from 'react';
import { BREAKPOINT } from '~/constants/Theme';
import { Button } from '~/components/commons/Button';
import Dropdown from './Dropdown';
import { Icon } from '~/components/commons/Icon';
import type { LargeMenu, LinkMenu } from '~/models/navigation.model';
import type { Link } from '~/models/link.model';

/**
 * Navbar Item react component
 * @returns {any} Navbar Item React component
 */
const NavItem = ({ item }: { item: Link | LinkMenu | LargeMenu }): any => {
  const [active, setActive] = useState(false);

  const onMouseDown = (e: any) => {
    if (window.innerWidth < BREAKPOINT.lg) {
      setActive(!active);
    }
    e.preventDefault();
  };

  const onKeyDown = (e: any) => {
    if (e.keyCode === 13 || e.keyCode === 32) {
      setActive(!active);
    }
  };

  const onMouseEnter = () => {
    window.innerWidth > BREAKPOINT.lg && setActive(true);
  };

  const onMouseLeave = () => {
    window.innerWidth > BREAKPOINT.lg && setActive(false);
  };

  const handleKeyBlur = useCallback((e: any) => {
      const currentTarget = e.currentTarget;
      requestAnimationFrame(() => {
        if (!currentTarget.contains(document.activeElement)) {
          setActive(false);
        }
      });
    }, [setActive]);


  return (
    <li className="flex flex-row items-center w-full lg:w-auto">
      {(item.type === 'linkMenu' || item.type === 'largeMenu') && (
        <div className="flex flex-col lg:inline-flex w-full lg:w-auto"
          onBlur={handleKeyBlur}
          onMouseLeave={onMouseLeave}>
          <button
            aria-haspopup="menu"
            type="button"
            className={`flex flex-row items-center justify-between lg:justify-start relative lg:before-nav-drop border-b border-primary-200 lg:border-b-0 ${active ? 'drop-active' : ''}`}
            onMouseUp={onMouseDown}
            onMouseEnter={onMouseEnter}
            onKeyDown={onKeyDown}
          >
            {item.text}{' '}
            <span
              className={`${
                active ? 'rotate-180 md:mt-1' : 'rotate-0 md:mt-0'
              } ml-2 h-4 flex items-center transition-150 pointer-events-none`}
            >
              <Icon type="solid" name="chevron-down" className="text-sm" />
            </span>
          </button>
          {active && (
            <Dropdown
              items={(item as LargeMenu | LinkMenu).links || []}
              sections={(item as LargeMenu).sections || []}
              highlight={(item as LargeMenu | LinkMenu).highlight}
              type={item.type}
              parentPage={(item as LargeMenu).parentPage}
            />
          )}
        </div>
      )}
      {item.type === 'link' && (
        <Button
          type="link"
          variant="none"
          to={(item as Link).url}
          className="w-full rounded-none lg:rounded border-b border-primary-100 lg:border-b-0 font-normal"
        >
          {(item as Link).text}
        </Button>
      )}
    </li>
  );
};

export default NavItem;
