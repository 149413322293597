'use client';

import { lazy, Suspense, useEffect, useState } from 'react';
import type { Flow, Options, Params } from 'react-chatbotify';
import type { AIAssistantChatHistory } from '~/models/ai-assistant-chat.model';
import type { SettingsData } from '~/models/settings.model';
import { useLoaderData } from '@remix-run/react';
import type { PageData } from '~/models/page-data.model';
import { cloudCockpitAIAssistantOptions } from '~/constants/ai-assistant.constants';

const ChatBot = lazy(() => import('react-chatbotify'));

export default function AIAssistant() {
  const [settings, setSettings] = useState<SettingsData>();
  const [isLoaded, setIsLoaded] = useState(false);
  const [isAssistantEnabled, setIsAssistantEnabled] = useState(false);
  const [aiIntroText, setAIIntroText] = useState('');
  const [chatHistory, setChatHistory] = useState<AIAssistantChatHistory>();
  const [aiAssistantOptions, setAIAssistantOptions] = useState<Options>();

  const data = useLoaderData<PageData>();

  useEffect(() => {
    setIsLoaded(true);
    const settingsActionUrl = '/ai-assistant/settings';

    const fetchSettings = async () => {
      try {
        const response = await fetch(settingsActionUrl, {
          method: 'GET',
          headers: { 'Content-Type': 'application/json' },
        });

        setSettings(await response.json());
      } catch (error) {
        console.error(error);
      }
    };

    fetchSettings();
  }, []);

  useEffect(() => {
    if (settings) {
      setIsAssistantEnabled(settings.isAIAssistantEnabled);
      setAIIntroText(settings.aiAssistantIntroText);
    }

    let aiAssistantOptions: Options | null = null;
    //TODO: Set this more scalable. Pass all string configurations to Strapi and get them from there via settings api
    if (data.websiteName === 'CloudCockpit') {
      aiAssistantOptions = cloudCockpitAIAssistantOptions;
    }
    if (aiAssistantOptions) {
      if (aiAssistantOptions.botBubble) {
        aiAssistantOptions.botBubble.avatar = settings ? settings.aiAssistantChatHeaderImageUrl : '';
      }
      if (aiAssistantOptions.header) {
        aiAssistantOptions.header.avatar = settings ? settings.aiAssistantResponseImageUrl : '';
      }
      if (aiAssistantOptions.chatButton) {
        aiAssistantOptions.chatButton.icon = settings ? settings.aiAssistantCallToActionImageUrl : '';
      }
      if (aiAssistantOptions.header) {
        aiAssistantOptions.header.title = settings ? settings.aiAssistantName : '';
        aiAssistantOptions.header.closeChatIcon = settings ? settings.aiAssistantCloseChatUrl : '';
      }
      if (aiAssistantOptions.chatInput) {
        aiAssistantOptions.chatInput.sendButtonIcon = settings ? settings.aiAssistantSendButtonUrl : '';
      }
      if (aiAssistantOptions.footer) {
        aiAssistantOptions.footer.text = settings ? settings.aiAssistantDisclaimerMessage : '';
      }

      setAIAssistantOptions(aiAssistantOptions);
    }
  }, [data.websiteName, settings]);

  const getResponse = async (params: Params) => {
    try {
      const aiAssistantSubmitActionUrl = `/ai-assistant/submit?userInput=${encodeURI(params.userInput)}`;

      let newChatHistory = chatHistory;
      if (!newChatHistory) {
        newChatHistory = { messages: [] };
      }

      newChatHistory.messages.push({ role: 'user', content: params.userInput });
      setChatHistory(newChatHistory);

      const requestAsString = JSON.stringify(newChatHistory);

      const response = await fetch(aiAssistantSubmitActionUrl, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: requestAsString,
      });

      const responseMessage = await response.json();

      if (newChatHistory.messages !== undefined && !responseMessage.error) {
        newChatHistory.messages.push({ role: 'assistant', content: responseMessage });
        setChatHistory(newChatHistory);
      }

      // remove user last message if we had an error in the response
      if (newChatHistory?.messages?.length % 2 !== 0) {
        newChatHistory.messages.splice(-1, 1);
      }

      for (let i = 0; i < responseMessage.length; i++) {
        await params.streamMessage(responseMessage.slice(0, i + 1));
        // ensure that response is given step by step (in a conversational way) instead of the whole response at once
        await new Promise((resolve) => setTimeout(resolve, 10));
      }
    } catch (error) {
      console.error(error);
    }
  };

  const flow: Flow = {
    start: {
      message: aiIntroText,
      path: 'loop',
    },
    loop: {
      message: async (params: Params) => {
        await getResponse(params);
      },
      path: 'loop',
    },
  };

  if (!isLoaded) {
    return null;
  }

  return (
    <Suspense fallback={<div>Loading...</div>}>
      {isAssistantEnabled && <ChatBot options={aiAssistantOptions} flow={flow} />}
    </Suspense>
  );
}
