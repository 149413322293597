import type { CtaComponent } from '~/models/cta.model';
import { Button } from './commons/Button';
import { useRef } from 'react';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';

const Cta = (props: CtaComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const refSection = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const section = props.section;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  useBackground(refSection, section?.background?.color, section?.background?.src);
  const layout = setLayoutSwitch('cta', settings?.layout);

  return (
    <div ref={refParent} data-layout={layout} className="cta s-parent">
      <div className="c-wrapper flex flex-col-reverse md:flex-row md:gap-8">
        <div ref={refSection} className={`c-text ${textColor} w-full flex items-center`}>
          <div className="flex flex-col justify-center gap-2 px-8 py-12 md:py-24 mx-auto">
            <div className="texts flex flex-col justify-center gap-2">
              {props.tagline && (
                <div className="tagline text-sm uppercase font-bold tracking-wider">{props.tagline}</div>
              )}
              {props.title && <h2 className="title text-2xl md:text-3xl font-bold">{props.title}</h2>}
              {/* {props.description && <div className="description text-lg">{props.description}</div>} */}
              {/* TODO: replace this with only richTextDescription */}
              {props.richTextDescription ? (
                <div
                  className="description text-lg"
                  dangerouslySetInnerHTML={{ __html: props.richTextDescription }}
                ></div>
              ) : (
                props.description && <div className="description text-lg">{props.description}</div>
              )}
            </div>
            {props.buttons && props.buttons.length > 0 && (
              <div className="buttons flex flex-row gap-2 mt-4">
                {props.buttons?.map((button, index) => (
                  <Button key={index} type="link" variant={button.variant} size="md" to={button.url}>
                    {button.text}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
        {settings?.layout === 'text-image' && props.imageUrl && (
          <div
            className="c-image w-full md:w-1/2 bg-cover bg-center bg-no-repeat"
            role="img"
            aria-label={props.title}
            style={{ backgroundImage: `url(${props.imageUrl})` }}
          ></div>
        )}
      </div>
    </div>
  );
};

export { Cta };
