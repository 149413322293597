import type { FormComponent } from '~/models/form.model';
import { Button } from './commons/Button';
import { useEffect, useRef, useState } from 'react';
import { setTextColor, useBackground } from '~/utils/component-settings';
import { Input } from './commons/Input';
import { Textarea } from './commons/Textarea';
import { Checkbox } from './commons/Checkbox';
import { Select } from './commons/Select';
import { useFetcher, Form as RemixForm } from '@remix-run/react';

import type {
  Checkbox as CheckboxField,
  EmailInput,
  Field,
  Label,
  NumberInput,
  Select as SelectField,
  TelInput,
  Textarea as TextareaField,
  TextInput,
} from '~/models/field.model';
import { Icon } from './commons/Icon';
import type { action as submitAction } from '~/routes/forms.submit';

const Form = (props: FormComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);

  return (
    <div ref={refParent} className="signup s-parent">
      <div
        className={`c-wrapper flex flex-col mx-auto gap-4 md:gap-8 px-8 py-8 md:py-16 w-full md:w-5/6 lg:max-w-screen-lg ${textColor}`}
      >
        {(props.title || props.title || props.description) && (
          <div className="c-text w-full flex flex-col md:text-center items-center">
            <div className="flex flex-col justify-center gap-2 pb-4 md:pb-8 md:mx-auto">
              <div className="texts flex flex-col gap-2">
                {props.tagline && <div className="tagline">{props.tagline}</div>}
                {props.title && <h2 className="title text-2xl md:text-3xl font-bold">{props.title}</h2>}
                {props.description && <div className="description text-lg">{props.description}</div>}
              </div>
            </div>
          </div>
        )}
        <FormFields {...props} />
      </div>
    </div>
  );
};

const FormFields = (props: FormComponent) => {
  const fetcher = useFetcher<typeof submitAction>();
  const refForm = useRef<HTMLFormElement | any>();
  const [disabled, setDisabled] = useState<boolean>(false);

  let successMessage = props.successMessage;

  if (!successMessage) {
    switch (props.locale) {
      case 'pt':
        successMessage = 'Enviado com sucesso!';
        break;
      case 'es':
        successMessage = '¡Enviado con éxito!';
        break;
      default:
        successMessage = 'Submitted successfully!';
        break;
    }
  }

  useEffect(() => {
    // Reset form after successfull submission
    if (fetcher.state === 'idle') {
      refForm.current.reset();
    }
  }, [fetcher.state]);

  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    // Submit and disable submit button - to avoid multiple repeated submissions
    e.preventDefault();
    setDisabled(true);
    const formData = new FormData(e.target as any);
    fetcher.submit(formData, { method: 'post', action: `/forms/submit?title=${encodeURI(props.title || '')}` });
  };

  return (
    <RemixForm
      method="post"
      ref={refForm}
      className="c-form w-full max-w-3xl mx-auto"
      onSubmit={submit}
      name={props.title?.toLowerCase().replace(/ /g, '_')}
    >
      <div className="fields grid sm:grid-cols-2 gap-x-4 gap-y-8">
        {props.fields && props.fields?.length > 0 && props.fields.map((x, index) => resolveField(x, index))}
      </div>
      {props.footer && (
        <div className="consent flex flex-row gap-2">
          {props.footer.consent && <Checkbox name="consent" className="consent-checkbox" required={true} />}
          {props.footer.text && (
            <label htmlFor="consent" className="text" dangerouslySetInnerHTML={{ __html: props.footer.text }}></label>
          )}
        </div>
      )}

      {!fetcher.data?.ok && (
        <div className="button flex flex-row justify-center gap-2 mt-8 md:mt-16">
          <Button type="submit" variant={props.button?.variant} size="md" disabled={disabled}>
            {props.button?.text || 'Submit'}
          </Button>
        </div>
      )}

      {fetcher.data?.ok && (
        <div className="success mx-auto p-8 flex flex-col justify-center items-center gap-4">
          <div className="success-header">
            <Icon name="circle-check" type="regular" className="icon text-4xl" />
          </div>
          <div className="success-message">{successMessage}</div>
        </div>
      )}
    </RemixForm>
  );
};

const resolveField = (x: Field, index: number): JSX.Element => {
  switch (x.type) {
    case 'text': {
      const textField = x as TextInput;
      return (
        <div className="field" key={index++}>
          <label htmlFor={textField.name}>
            {textField.name} {textField.required ? <span className="required">*</span> : <></>}
          </label>
          <Input
            id={textField.name}
            name={textField.name}
            placeholder={textField.placeholder}
            pattern={textField.pattern}
            type="text"
            required={textField.required}
          />
        </div>
      );
    }
    case 'email': {
      const emailField = x as EmailInput;
      return (
        <div className="field" key={index++}>
          <label htmlFor={emailField.name}>
            {emailField.name} {emailField.required ? <span className="required">*</span> : <></>}
          </label>
          <Input
            id={emailField.name}
            name={emailField.name}
            placeholder={emailField.placeholder}
            pattern={emailField.pattern}
            type="email"
            required={emailField.required}
          />
        </div>
      );
    }
    case 'tel': {
      const telField = x as TelInput;
      return (
        <div className="field" key={index++}>
          <label htmlFor={telField.name}>
            {telField.name} {telField.required ? <span className="required">*</span> : <></>}
          </label>
          <Input
            id={telField.name}
            name={telField.name}
            placeholder={telField.placeholder}
            pattern={telField.pattern}
            type="tel"
            required={telField.required}
          />
        </div>
      );
    }
    case 'number': {
      const numberField = x as NumberInput;
      return (
        <div className="field" key={index++}>
          <label htmlFor={numberField.name}>
            {numberField.name} {numberField.required ? <span className="required">*</span> : <></>}
          </label>
          <Input
            id={numberField.name}
            name={numberField.name}
            placeholder={numberField.placeholder}
            pattern={numberField.pattern}
            type="number"
            required={numberField.required}
          />
        </div>
      );
    }
    case 'textarea': {
      const textarea = x as TextareaField;
      return (
        <div className="field sm:col-span-2" key={index++}>
          <label htmlFor={textarea.name}>
            {textarea.name} {textarea.required ? <span className="required">*</span> : <></>}
          </label>
          <Textarea
            id={textarea.name}
            name={textarea.name}
            rows={4}
            placeholder={textarea.placeholder}
            required={textarea.required}
          />
        </div>
      );
    }
    case 'checkbox': {
      const checkbox = x as CheckboxField;
      return (
        <div className="field sm:col-span-2" key={index++}>
          <div className="relative flex items-center">
            <Checkbox
              className="consent-checkbox mr-1"
              name={checkbox.text?.toLocaleLowerCase().replace(/ /g, '_')}
              required={true}
            />
            <label style={{ paddingBottom: 0 }} htmlFor={checkbox.text?.toLocaleLowerCase().replace(/ /g, '_')}>
              {checkbox.text}
            </label>
          </div>
        </div>
      );
    }
    case 'select': {
      const select = x as SelectField;
      return (
        <div className="field" key={index++}>
          <label htmlFor={select.name}>
            {select.name} {select.required ? <span className="required">*</span> : <></>}
          </label>
          <div className="relative">
            <Select name={select.name} className="w-full">
              <Select.Options>
                <Select.Option value="" disabled={true}>
                  {select.placeholder}
                </Select.Option>
                {select.options?.map((option: { id: number; name: string }) => (
                  <Select.Option key={option.id} value={option.name.toLowerCase().replace(/ /g, '_')}>
                    {option.name}
                  </Select.Option>
                ))}
              </Select.Options>
            </Select>
          </div>
        </div>
      );
    }
    case 'label': {
      const label = x as Label;
      return (
        <div className="field sm:col-span-2" key={index++}>
          <p className={label.style === 'bold' ? 'font-bold' : ''}>{label.text}</p>
        </div>
      );
    }
    default:
      return <></>;
  }
};

export { Form };
