export const getLocaleFromPath = (path: string) => {
  let locale = 'en';

  if (!path) {
    return locale;
  }

  const firstPath = path.split('/')[1];

  if (firstPath === 'pt' || firstPath === 'es') {
    locale = firstPath;
  }

  return locale;
};
