import { useEffect, useRef } from 'react';
import type { IframeFormComponent } from '~/models/iframe-form.model';
import { setTextColor, useBackground } from '~/utils/component-settings';

const IframeForm = (props: IframeFormComponent) => {
  const { title, description, rdStationID, googleID } = props;
  const settings = props.settings;
  const refParent = useRef<HTMLDivElement>(null);
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);

  useEffect(() => {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = 'https://d335luupugsy2.cloudfront.net/js/rdstation-forms/stable/rdstation-forms.min.js';
    script.async = true;

    const handleScriptLoad = () => {
      try {
        const RDStationFormsInstance = new RDStationForms(rdStationID, googleID);

        RDStationFormsInstance.createForm();
        console.log('Form successfully rendered.');

        // Optional: Add an event listener for form submission
        RDStationFormsInstance.onSubmit((response: any) => {
          console.log('Form submitted successfully:', response);
        });
      } catch (error) {
        console.error('Error rendering form:', error);
      }
    };

    // Attach the handleScriptLoad function to the script's onload event
    script.onload = handleScriptLoad;

    // Append the script to the document body
    document.body.appendChild(script);

    return () => {
      // Clean up the script when the component is unmounted
      document.body.removeChild(script);
    };
  }, [rdStationID, googleID]);

  return (
    <div ref={refParent} className="signup s-parent">
      <div
        className={`c-wrapper flex flex-col mx-auto gap-4 md:gap-8 px-8 py-8 md:py-16 w-full md:w-5/6 lg:max-w-screen-lg ${textColor}`}
      >
        {(props.title || props.description) && (
          <div className="c-text w-full flex flex-col md:text-center items-center">
            <div className="flex flex-col justify-center gap-2 pb-4 md:pb-8 md:mx-auto">
              <div className="texts flex flex-col gap-2">
                {props.title && <h2 className="title text-2xl md:text-3xl font-bold">{props.title}</h2>}
                {props.description && <div className="description text-lg">{props.description}</div>}
              </div>
            </div>
          </div>
        )}
        <div role="main" id={rdStationID}></div>
      </div>
    </div>
  );
};

export { IframeForm };
