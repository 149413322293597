import { useLoaderData } from '@remix-run/react';
import { type ReactNode } from 'react';
import AIAssistant from '~/components/ai-assistant/AIAssistant';
import { Footer } from '~/components/shell/footer';
import { Nav } from '~/components/shell/nav';
import type { PageData } from '~/models/page-data.model';

const Layout = (props: { children: ReactNode | ReactNode[] }) => {

  const data = useLoaderData<PageData>();
  const metadata = data?.metadata;

  return (
    <>
      <Nav navigation={metadata?.navigation || {}} meta={metadata?.meta || {}} />
      {props.children}
      <Footer footer={metadata?.footer || {}} title={metadata?.meta?.title || ''} />

      <AIAssistant />

      {metadata?.meta?.bodyScripts &&
        metadata.meta.bodyScripts.length > 0 &&
        metadata.meta.bodyScripts.map((script, index) => (
          <script key={index} type="text/javascript" async src={script}></script>
        ))}
    </>
  );
};

export { Layout };
