import { useRef } from 'react';
import type { RichTextComponent } from '~/models/rich-text.model';
import { useBackground } from '~/utils/component-settings';

const RichText = ({ body, settings }: RichTextComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);

  return <div ref={refParent} className="content-view-richtext" dangerouslySetInnerHTML={{ __html: body || '' }}></div>;
};

export { RichText };
