import type { ListData } from '~/models/list-data.model';
import type { PageData } from '~/models/page-data.model';

export const generateMetaTag = (data: PageData | ListData | undefined) => {
  let meta: any[] = [{ charset: 'utf-8' }, { name: 'viewport', content: 'width=device-width,initial-scale=1' }];

  if (!data) {
    return meta;
  }

  const title = data.metadata?.seo?.metaTitle || data.metadata?.meta?.title;
  const description = data.metadata?.seo?.metaDescription;
  const image = data.metadata?.seo?.metaImageUrl;

  meta = [
    ...meta,
    { title: title },
    { property: 'og:title', content: title },
    { name: 'keywords', content: data.metadata?.seo?.keywords },
    { name: 'description', content: description },
    { property: 'og:description', content: description },
    { property: 'og:image', content: image },
    { property: 'og:site_name', content: data.metadata?.meta?.title },
    { property: 'twitter:title', content: title },
    { property: 'twitter:description', content: description },
    { property: 'twitter:image', content: image },
    { property: 'twitter:card', content: 'summary_large_image' },
    { property: 'twitter:image:alt', content: title },
    { property: 'facebook:title', content: title },
    { property: 'facebook:description', content: description },
    { property: 'facebook:image', content: image },
    { property: 'facebook:image:alt', content: title },
    { name: 'robots', content: data.metadata?.seo?.metaRobots },
  ];

  if (data.metadata?.seo?.metaSocial !== undefined && data.metadata?.seo?.metaSocial?.length > 0) {
    for (const social of data.metadata.seo.metaSocial) {
      if (social.socialNetwork === 'Twitter') {
        meta = meta.filter((x) => x.property === undefined || !x.property.startsWith('twitter'));
        meta.push({ property: 'twitter:title', content: social.title || description });
        meta.push({ property: 'twitter:description', content: social.description || description });
        meta.push({ property: 'twitter:image', content: social.imageUrl || image });
        meta.push({ property: 'twitter:image:alt', content: social.title || title });
      } else if (social.socialNetwork === 'Facebook') {
        meta = meta.filter((x) => x.property === undefined || !x.property.startsWith('facebook'));
        meta.push({ property: 'facebook:title', content: social.description || description });
        meta.push({ property: 'facebook:description', content: social.description || description });
        meta.push({ property: 'facebook:image', content: social.imageUrl || image });
        meta.push({ property: 'facebook:image:alt', content: social.title || title });
      }
    }
  }

  if (data?.metadata?.meta?.faviconUrl) {
    meta.push({ tagName: 'link', rel: 'icon', href: data.metadata?.meta?.faviconUrl });
  }

  if (data?.metadata?.seo?.canonicalURL) {
    meta.push({ tagName: 'link', rel: 'canonical', href: data.metadata?.seo?.canonicalURL });
  }

  return meta;
};
