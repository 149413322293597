import type { Options } from 'react-chatbotify';

export const cloudCockpitAIAssistantOptions: Options = {
  theme: {
    embedded: false,
    fontFamily: 'Montserrat, sans-serif;',
    showFooter: true,
    primaryColor: 'rgb(87, 31, 255)',
    secondaryColor: 'rgb(87, 31, 255)',
  },
  botBubbleStyle: { backgroundColor: 'rgb(242,242,242)', color: 'rgb(0,0,0)' },
  botBubble: {
    dangerouslySetInnerHtml: true,
    showAvatar: true,
    avatar: '',
  },
  chatHistory: { storageKey: 'ai-cloudcockpit-assistant-conversation' },
  chatInput: { sendButtonIcon: '' },
  tooltip: { mode: 'NEVER', text: '' },
  notification: { disabled: true },
  header: { title: '', avatar: '', closeChatIcon: '' },
  chatButton: { icon: '' },
  emoji: { disabled: true },
  fileAttachment: { disabled: true },
  footer: {
    text: 'The responses are generated by AI and may contain errors. For official details, please contact our team.',
  },
  footerStyle: { alignItems: 'center', justifyContent: 'center', lineHeight: '1.5' },
};
