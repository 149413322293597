import Image, { type ImageProp } from '~/components/commons/Image';
import type { Client } from '~/models/clients.model';

type ClientsLogosProps = {
  logos?: Client[];
  grayscale?: boolean;
};

const ClientLogo = (props: ImageProp) => {
  return (
    <>
      <div className="w-full flex justify-center">
        <Image src={props.src} alt={props.alt} grayscale={props.grayscale} />
      </div>
    </>
  );
};

const ClientsLogos = (props: ClientsLogosProps) => {
  return (
    <>
      {props.logos
        ?.filter((client: Client) => !!client)
        ?.map((client: Client, index: number) => (
          <ClientLogo key={index} src={client.imageUrl} alt={client.name} grayscale={props.grayscale} />
        ))}
    </>
  );
};

export { ClientsLogos, ClientLogo };
