import { useRef } from 'react';
import type { HeroComponent } from '~/models/hero.model';
import { Button } from './commons/Button';
import { VideoPlayer } from './commons/VideoPlayer';
import { setTextColor, useBackground, setLayoutSwitch } from '~/utils/component-settings';

const Hero = (props: HeroComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const refImage = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  useBackground(refImage, settings?.background?.color, props.contentImageUrl);
  const layout = setLayoutSwitch('hero', settings?.layout);

  return (
    <div data-layout={layout} className={`${layout} hero-${settings?.size} hero s-parent`} ref={refParent}>
      <div className="c-wrapper h-full flex gap-8">
        {/* Hero Text */}
        <div className={`c-wrapper-text ${textColor} w-full flex items-center`}>
          <div className="c-content flex flex-col justify-center gap-2 px-8 w-full">
            {props.title && (
              <h1
                className="title text-3xl md:text-4xl font-bold"
                dangerouslySetInnerHTML={{ __html: props.title || '' }}
              ></h1>
            )}
            {props.description && <div className="description text-lg">{props.description}</div>}
            {((props.buttons && props.buttons.length > 0) || (props.video?.videoUrl && props.video.buttonText)) && (
              <div className="buttons flex flex-row gap-2 mt-4">
                {props.video?.videoUrl && props.video.buttonText && (
                  <Button type="modal" variant={props.video.buttonVariant} size="md" video={props.video}>
                    {props.video.buttonText}
                  </Button>
                )}
                {props.buttons?.map((button, index) => (
                  <Button
                    key={index}
                    type="link"
                    variant={button.variant}
                    size="md"
                    to={button.url}
                  >
                    {button.text}
                  </Button>
                ))}
              </div>
            )}
          </div>
        </div>
        {/* Hero Video */}
        <div className="c-wrapper-image flex items-center p-4 md:p-8 bg-no-repeat bg-center bg-cover" ref={refImage}>
          { props.video?.videoUrl && !props.video?.buttonText && (
            <div className="c-wrapper-image-video w-full">
              <VideoPlayer
                videoUrl={props.video?.videoUrl}
                title={props.title}
                thumbnailUrl={props.video?.thumbnailUrl}
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export { Hero };
