import { useCallback, useEffect, useState } from 'react';

const VAR_NAME = '--base-vh';

const getCurrentVh = () => Number((window.innerHeight * 0.01).toFixed(2));
let count = 0;

const useWindowSize = (): number => {
  const [vh, setVh] = useState(0);
  const updateVh = useCallback(() => {
    const newVh = getCurrentVh();
    document.documentElement.style.setProperty(VAR_NAME, `${newVh}px`);
    setVh(newVh);
  }, [setVh]);

  useEffect(() => {
    if (typeof window !== 'undefined') {
      count += 1;
      updateVh();
      window.addEventListener('resize', updateVh);
    }
    return () => {
      window.removeEventListener('resize', updateVh);
      count -= 1;

      if (count === 0) {
        document.documentElement.style.removeProperty(VAR_NAME);
      }
    };
  }, [updateVh]);

  return vh;
};

export default useWindowSize;
