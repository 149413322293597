/* eslint-disable jsx-a11y/img-redundant-alt */
import type { Content } from '~/models/header.model';
import { Card } from './components/Card';

type HeaderCardsProp = {
  content: Content[] | any;
};

const HeaderCards = ({ content }: HeaderCardsProp) => {
  return (
    <>
      {content?.map((card: HeaderCardsProp, index: number) => (
        <Card key={index} content={card} />
      ))}
    </>
  );
};

export default HeaderCards;
