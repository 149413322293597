import type { ListItem } from '~/models/list-item.model';
import type { StackedSettings } from '~/models/component-settings.model';
import { Card } from '../commons/Card';

type StackedProps = {
  list?: ListItem[];
  layout?: StackedSettings['layout'];
};

const StackedList = ({ list, layout }: StackedProps) => {
  return (
    <div className={`flex flex-col ${layout !== 'list' ? 'md:flex-row' : ''} gap-4`}>
      {list?.map((card: ListItem, index: number) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};

export default StackedList;
