import type { PeopleComponent, Person } from '~/models/people.model';
import { useRef } from 'react';
import { setTextColor, useBackground } from '~/utils/component-settings';
import { ProfileCard } from './people/ProfileCard';

const People = (props: PeopleComponent) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const refSection = useRef<HTMLDivElement | null>(null);
  const section = props.section;
  const settings = props.settings;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);
  useBackground(refSection, section?.background?.color, section?.background?.src);

  return (
    <div ref={refParent} className="people s-parent">
      <div className={`c-wrapper relative px-8 py-12 ${textColor}`}>
        <div className="c-content relative z-10 mx-auto flex flex-col gap-8">
          <div className="c-text texts flex flex-col gap-2 md:text-center">
            {section?.title && <div className="title font-bold text-2xl md:text-3xl">{section?.title}</div>}
            {/* {section?.description && <div className="description text-lg">{section?.description}</div>} */}
            {/* TODO: replace this with only richTextDescription */}
            {section?.richTextDescription ? (
              <div
                className="description text-lg"
                dangerouslySetInnerHTML={{ __html: section?.richTextDescription }}
              ></div>
            ) : (
              section?.description && <div className="description text-lg">{section?.description}</div>
            )}
          </div>
          <div className="c-profiles grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 2xl:max-w-screen-xl 2xl:mx-auto gap-x-4 gap-y-8">
            {props.people?.map((profile: Person, index: number) => (
              <ProfileCard key={index} {...profile} />
            ))}
          </div>
        </div>
        <div ref={refSection} className="c-section-background h-full max-h-80 w-full absolute top-0 left-0"></div>
      </div>
    </div>
  );
};

export { People };
