import type { CollapseComponent, CollapseItem } from '~/models/collapse.model';
import { useRef, useState } from 'react';
import { setTextColor, useBackground } from '~/utils/component-settings';
import { Icon } from './commons/Icon';
import { Button } from './commons/Button';

const Collapse = (props: CollapseComponent) => {
  const [activeCollapse, setActiveCollapse] = useState<number>();
  const refParent = useRef<HTMLDivElement | null>(null);
  const settings = props.settings;
  const textColor = setTextColor(settings?.background?.text);
  useBackground(refParent, settings?.background?.color, settings?.background?.src);

  const handleClick = (index: number) => {
    if (activeCollapse === index) {
      setActiveCollapse(undefined);
    } else {
      setActiveCollapse(index);
    }
  };

  return (
    <div ref={refParent} className="collapsible s-parent">
      <div className="c-wrapper flex flex-col items-center w-full md:gap-8 lg:gap-16 mx-auto px-8 py-12">
        <div className={`c-text w-full ${textColor}`}>
          <div className="texts flex flex-col gap-2 w-full md:w-9/12">
            <div className="title text-2xl md:text-3xl font-bold">{props.title}</div>
            {props.description && <div className="description text-lg pb-8 md:pb-0 mt-4">{props.description}</div>}
          </div>
        </div>
        <div className="c-collapse w-full max-w-4xl 2xl:max-w-screen-xl space-y-4">
          {props.items?.map((item: CollapseItem, index: number) => (
            <button
              type="button"
              key={index}
              onClick={() => {
                handleClick(index);
              }}
              className="w-full text-left rounded-lg bg-white p-4"
            >
              <div className="header flex flex-row gap-4">
                {settings?.showIcons && item.icon && (
                  <div className="header-icon w-6">
                    <Icon type={item.icon?.type} name={item.icon?.name} className="text-2xl text-primary-600" />
                  </div>
                )}
                <div className="header-text flex-grow">
                  <div className="title text-xl font-bold">{item.title}</div>
                  {item.excerpt && <div className="excerpt">{item.excerpt}</div>}
                </div>
                <div className="header-icon-tab pl-4 pr-1 flex-shrink-0 text-primary-600 text-lg">
                  <Icon type="solid" name={activeCollapse === index ? 'minus' : 'plus'} />
                </div>
              </div>
              {activeCollapse === index && (
                <div className="content border-t border-primary/30 mt-4 pt-4 pb-2">
                  {/* <div className="content-text opacity-80">{item.text}</div> */}
                  {/* TODO: replace this with only richText */}
                  {item.richText ? (
                    <div className="content-text" dangerouslySetInnerHTML={{ __html: item.richText }}></div>
                  ) : (
                    <div className="content-text">{item.text}</div>
                  )}
                  {item.buttons && item.buttons.length > 0 && (
                    <div className="buttons flex flex-row items-center gap-2 mt-4">
                      {item.buttons?.map((button, index) => (
                        <Button key={index} type="link" variant={button.variant} size="sm" to={button.url}>
                          {button.text}
                        </Button>
                      ))}
                    </div>
                  )}
                </div>
              )}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export { Collapse };
