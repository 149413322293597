export const BREAKPOINT = {
  md: 768,
  lg: 1024,
};

class Layout {
  layout?: string;
  css?: string;
}

class Layouts {
  [layout: string]: Layout[];
}

export const LAYOUTS_NAMES: Layouts = {
  hero: [
    {
      layout: 'right',
      css: 'right',
    },
    {
      layout: 'center',
      css: 'center',
    },
    {
      layout: 'left',
      css: 'left',
    },
  ],
  header: [
    {
      layout: 'default',
      css: 'default',
    },
    {
      layout: 'minimal',
      css: 'minimal',
    },
    {
      layout: 'feature',
      css: 'feature',
    },
    {
      layout: 'cards',
      css: 'cards',
    },
    {
      layout: 'article',
      css: 'article',
    },
  ],
  cta: [
    {
      layout: 'text-center',
      css: 'text-center',
    },
    {
      layout: 'text-image',
      css: 'text-image',
    },
    {
      layout: 'text-left-button-overlap',
      css: 'text-left-button-overlap',
    },
    {
      layout: 'minimal-center',
      css: 'minimal-center',
    },
    {
      layout: 'text-button-row',
      css: 'text-button-row',
    },
    {
      layout: 'imagery',
      css: 'imagery',
    },
  ],
  clients: [
    {
      layout: 'default',
      css: 'default',
    },
    {
      layout: 'grid',
      css: 'grid',
    },
    {
      layout: 'minimal',
      css: 'minimal',
    },
  ],
  testimonials: [
    {
      layout: 'basic',
      css: 'basic',
    },
    {
      layout: 'float',
      css: 'float',
    },
    {
      layout: 'profile',
      css: 'profile',
    },
  ],
  metrics: [
    {
      layout: 'grid',
      css: 'grid',
    },
  ],
  feature: [
    {
      layout: 'cards',
      css: 'cards',
    },
    {
      layout: 'grid',
      css: 'grid',
    },
    {
      layout: 'columns',
      css: 'columns',
    },
    {
      layout: 'tabs',
      css: 'tabs',
    },
  ],
  stacked: [
    {
      layout: 'list',
      css: 'list',
    },
  ],
  feed: [
    {
      layout: 'cards',
      css: 'cards',
    },
    {
      layout: 'grid',
      css: 'grid',
    },
    {
      layout: 'sidebar',
      css: 'sidebar',
    },
    {
      layout: 'list',
      css: 'list',
    },
  ],
  pageHeader: [
    {
      layout: 'center',
      css: 'center',
    },
    {
      layout: 'left',
      css: 'left',
    },
  ],
  recentArticles: [
    {
      layout: 'cards',
      css: 'cards',
    },
    {
      layout: 'grid',
      css: 'grid',
    },
    {
      layout: 'list',
      css: 'list',
    },
  ],
  pageList: [
    {
      layout: 'cards',
      css: 'cards',
    },
    {
      layout: 'grid',
      css: 'grid',
    },
    {
      layout: 'list',
      css: 'list',
    },
  ],
};
