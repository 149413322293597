import type { DsPricingSimulatorComponent } from '~/models/ds-pricing-simulator.model';
import { useState } from 'react';
import { Button } from './commons/Button';
import { getLocaleFromPath } from '~/utils/locale';
import { useLocation } from '@remix-run/react';

const DsPricingSimulator = (props: DsPricingSimulatorComponent) => {
  const MAX_USERS = 2500;
  const MIN_USERS = 50;
  const DEFAULT_PRICE = 250;
  const [users, setUsers] = useState<number | null>(MIN_USERS);
  const [price, setPrice] = useState<number | any>(DEFAULT_PRICE);
  const [usersLimit, setUsersLimit] = useState<boolean>(false);
  const location = useLocation();
  const locale = getLocaleFromPath(location.pathname);

  const calculatePrice = (users: number) => {
    if (users < 0) {
      users = 0;
    } else if (users > MAX_USERS) {
      users = MAX_USERS;
    }

    let total = DEFAULT_PRICE;

    if (users > 250) {
      total += (250 - 50) * 2;

      if (users > 1000) {
        total += (1000 - 250) * 1;
        total += (users - 1000) * 0.6;
      } else {
        total += (users - 250) * 1;
      }
    } else if (users > 50) {
      total += (users - 50) * 2;
    }

    return { price: total };
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;

    if (!+value) {
      setUsers(null);
      setPrice(DEFAULT_PRICE);
    } else {
      const newValue = value ? parseInt(value) : null;
      if (newValue && newValue < MAX_USERS) {
        setUsersLimit(false);
        setUsers(newValue);
        setPrice(calculatePrice(newValue).price);
      } else {
        setUsersLimit(true);
        setUsers(MAX_USERS);
        setPrice(calculatePrice(MAX_USERS).price);
      }
    }
  };

  const handleBlur = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = +e.target.value;

    if (!value || value < MIN_USERS) {
      setUsers(MIN_USERS);
      setPrice(DEFAULT_PRICE);
    }
  };

  const formatPrice = (price: number) => {
    const options = { style: 'currency', currency: 'EUR', currencyDisplay: 'code' };
    const cleanPrice = new Intl.NumberFormat('fr', options).format(price).replace('EUR', '').trim();
    return cleanPrice;
  };

  let translate: { [key: string]: string };
  const localeMaxUsers = MAX_USERS.toLocaleString('pt');

  switch (locale) {
    case 'pt':
      translate = {
        for: 'Quero diggspace para',
        users: 'utilizadores',
        monthly: 'mês',
        yearly: 'anual',
        vat: 'IVA não incluído',
        warning:
          'Para organizações com mais de ' + localeMaxUsers + ' usuários, entre em contato conosco para obter preços.',
        defaultText: 'Contacte a equipa Comercial para condições especiais',
        button: 'Contactar equipa Comercial',
      };
      break;
    case 'es':
      translate = {
        for: 'Quiero diggspace para',
        users: 'usuarios',
        monthly: 'mes',
        yearly: 'anual',
        vat: 'Sin IVA',
        warning:
          'Para organizaciones con más de ' + localeMaxUsers + ' usuarios, contáctenos para conocer los precios.',
        defaultText: 'Contacte a equipa Comercial para condições especiais',
        button: 'Contactar con Ventas',
      };
      break;
    default:
      translate = {
        for: 'I want diggspace for',
        users: 'users',
        monthly: 'mo',
        yearly: 'yearly',
        vat: 'without VAT',
        warning: 'For organizations with more than ' + localeMaxUsers + ' users, please contact us for pricing.',
        defaultText: 'Contact sales for your specific case',
        button: 'Contact Sales',
      };
  }

  return (
    <div className="simulator s-parent">
      <div className="c-wrapper flex justify-center">
        <div className="m-simulator w-full max-w-screen-xl md:min-h-[30rem] flex flex-col sm:flex-row px-4 py-16 lg:p-20">
          <div className="a-users w-full sm:w-1/2 md:w-7/12 bg-primary border border-primary text-white text-xl flex flex-col md:flex-row md:items-center justify-center gap-2 p-8">
            <label htmlFor="users">{translate?.for}</label>
            <div className="flex flex-row items-center gap-2">
              <input
                type="number"
                id="users"
                name="users"
                min={0}
                max={MAX_USERS}
                value={users ? users.toString() : ''}
                onChange={handleInputChange}
                onBlur={handleBlur}
                inputMode="numeric"
                className="text-black w-28 font-bold text-2xl rounded-l-none rounded-r-full"
              />
              <span>{translate?.users}</span>
            </div>
          </div>
          <div className="a-results w-full sm:w-1/2 md:w-5/12 sm:rounded-r-full bg-white border border-primary flex flex-col justify-center gap-1 p-8">
            <div className="flex flex-col gap-0.5">
              <div className="monthly">
                <p>
                  <span className="text-3xl font-bold tracking-wide">€ {formatPrice(price)}</span>
                  <span className="text-xl ml-1.5 tracking-wide">/{translate?.monthly}</span>
                </p>
              </div>
              <div className="yearly">
                <p className="text-base font-medium text-neutral-400 tracking-wide">
                  € {formatPrice(price * 12)} {translate?.yearly}
                </p>
              </div>
              <div className="disclaimer mt-1">
                <p className="text-xs font-bold">({translate?.vat})</p>
              </div>
            </div>
            <div className="flex flex-col">
              <p className="text-primary font-bold">_</p>
              {users && usersLimit && (
                <div className="warning font-medium text-sm mb-1">
                  <p>{translate?.warning}</p>
                </div>
              )}
              {users && !usersLimit && (
                <div className="warning font-medium text-sm mb-1">
                  <p>{translate?.defaultText}</p>
                </div>
              )}
              <div className="flex flex-row gap-2">
                {props.buttons && props.buttons.length > 0 && (
                  <div className="buttons flex flex-row gap-2">
                    {props.buttons?.map((button, index) => (
                      <Button
                        key={index}
                        type="link"
                        variant={button.variant}
                        size="sm"
                        to={button.url}
                        className="mt-1 w-fit"
                      >
                        {translate?.button}
                      </Button>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export { DsPricingSimulator };
