import { Button } from '~/components/commons/Button';
import { Icon } from '../Icon';

type CarouselDotsProps = {
  selected: any;
  onClick: () => void;
};

type CarouselButtonsProps = {
  enabled?: boolean;
  onClick: () => void;
};

export const DotButton = ({ selected, onClick }: CarouselDotsProps) => (
  <Button variant="none" className={`${selected ? 'is-selected' : ''}`} onClick={onClick}>
    {''}
  </Button>
);

export const PrevButton = ({ enabled, onClick }: CarouselButtonsProps) => (
  <Button variant="square" className="button--prev" onClick={onClick} disabled={!enabled}>
    <Icon type="solid" name="chevron-left px-2" />
  </Button>
);

export const NextButton = ({ enabled, onClick }: CarouselButtonsProps) => (
  <Button variant="square" className="button--next" onClick={onClick} disabled={!enabled}>
    <Icon type="solid" name="chevron-right px-2" />
  </Button>
);
