import { Link } from '@remix-run/react';
import { useEffect, useRef, useState } from 'react';
import type { Footer as FooterProps } from '~/models/footer.model';
import { setTextColor, useBackground } from '~/utils/component-settings';
import { Icon } from '../commons/Icon';
import { Button } from '../commons/Button';

const Footer = (props: { footer: FooterProps; title: string }) => {
  const refParent = useRef<HTMLDivElement | null>(null);
  const footer = props.footer;
  const textColor = setTextColor(footer?.background?.text);
  const year = new Date().getFullYear();
  useBackground(refParent, footer?.background?.color, footer?.background?.src);

  return (
    <footer ref={refParent} className={`s-parent bg-slate-200 ${textColor}`}>
      <div className="c-wrapper flex flex-col p-8 pt-12">
        <div className="c-main pb-2 flex flex-col-reverse gap-16 md:gap-0 md:flex-row justify-between items-start">
          <div className="main-l flex flex-col justify-between min-h-[10rem]">
            <div className="logo w-fit">
              <Link to="/" aria-label={props.title}>
                <img src={footer.logoUrl} alt={props.title} className="w-44 md:w-48" />
              </Link>
            </div>
            <div
              className="description lg:-mt-6 text-lg leading-snug"
              dangerouslySetInnerHTML={{ __html: footer.description || '' }}
            ></div>
            {footer.socialMedia && (
              <div className="social flex flex-row gap-3">
                {footer.socialMedia?.map((social, index) => (
                  <a
                    className="social-item"
                    key={index}
                    href={social.url}
                    target="_blank"
                    rel="noreferrer"
                    aria-label={social?.text}
                  >
                    <Icon name={social.icon?.name} type={social.icon?.type} className="text-2xl" />
                  </a>
                ))}
              </div>
            )}
          </div>
          <div className="main-r w-full sm:w-auto grid grid-cols-2 sm:flex sm:flex-row justify-end gap-8 sm:gap-16">
            {footer.footerBlock?.map((section, index) => (
              <div className="section" key={index}>
                <div className="section-title font-bold mb-4">{section.title}</div>
                <div className="section-items flex flex-col gap-2.5 max-w-[120px]">
                  {section.links?.map((item, index) => (
                    <Link to={item.url ?? '/'} key={index} aria-label={item.text} className="leading-4">
                      {item.text}
                    </Link>
                  ))}
                </div>
              </div>
            ))}
          </div>
        </div>
        <div className="c-secondary md:h-8 flex flex-col md:flex-row md:items-center">
          <div className="h-[1px] mt-4 md:mt-0 bg-neutral-500 w-full"></div>
          <div className="copyright text-base min-w-fit pt-4 md:pt-0 md:pl-16">
            {year} © {footer.copyright}
          </div>
        </div>
      </div>
      <div className="c-wrapper relative py-6">
        <BackToTop />
      </div>
    </footer>
  );
};

const BackToTop = () => {
  const refScrollToTop = useRef<HTMLDivElement | null>(null);
  const [showScroll, setShowScroll] = useState<boolean>(false);
  const SCROLL_OFFSET = 800;

  useEffect(() => {
    const handleScroll = () => {
      if (refScrollToTop.current) {
        if (window.scrollY > SCROLL_OFFSET) {
          setShowScroll(true);
        } else {
          setShowScroll(false);
        }
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div ref={refScrollToTop} className={`${showScroll ? 'opacity-100':'opacity-0 pointer-events-none'} transition-opacity ease-linear hidden sm:flex absolute w-full justify-end bottom-0 right-8`}>
      <Button onClick={scrollToTop} type="button" variant="solid" className={`${showScroll ? 'bottom-4':'bottom-0'} fixed z-50`}>
        <Icon name="arrow-up" type="solid" />
      </Button>
    </div>
  );
};

export { Footer };
