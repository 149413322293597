import { useEffect, useRef } from 'react';
import type { CookieScriptComponent } from '~/models/cookie-script.model';

const CookieScript = (props: CookieScriptComponent) => {
  const { src } = props;
  const refParent = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const script = document.createElement('script');
    script.async = true;
    script.type = 'text/javascript';
    script.src = src || '';
    script.setAttribute('data-cookiescriptreport', 'report');

    if (refParent.current) {
      const parentElem = refParent.current;
      parentElem.appendChild(script);

      return () => {
        if (parentElem) {
          parentElem.removeChild(script);
        }
      };
    }
  }, [src]);

  return (
    <div ref={refParent} className="content-view-cookies"></div>
  );
};

export { CookieScript };
