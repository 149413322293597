import type { ListItem } from '~/models/list-item.model';
import { Card } from '../commons/Card';

type FeedCardsProps = {
  list?: ListItem[];
};

const FeedCards = ({ list }: FeedCardsProps) => {
  return (
    <div className="grid sm:grid-cols-2 md:grid-cols-3 gap-4">
      {list?.map((card: ListItem, index: number) => (
        <Card key={index} {...card} />
      ))}
    </div>
  );
};

export default FeedCards;
