import type { Pagination as PaginationModel } from '~/models/list-result.model';
import { Button } from './Button';
import { Icon } from './Icon';
import { Input } from './Input';

const PaginationInput = (props: PaginationModel) => {
  const submitForm = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    e.stopPropagation();
    const pageNumber = +(e.target as any)[0].value;
    navigate(pageNumber);
  };

  const navigate = (pageNumber: number) => {
    // Only submit if it's a valid page number
    if (pageNumber !== props.page && pageNumber <= props.totalPages && pageNumber > 0) {
      const query = new URLSearchParams(document.location.search);

      if (query.has('page')) {
        query.set('page', pageNumber.toString());
      } else {
        query.append('page', pageNumber.toString());
      }

      window.location.href = document.location.pathname + '?' + query.toString();
    }
  };

  return (
    <div className="pagination w-full flex flex-row gap-2 justify-between md:justify-center items-center">
      <Button
        type="button"
        variant="outline"
        disabled={props.page === 1}
        className="pagination-arrow"
        aria-label="previous page"
        onClick={() => navigate(props.page - 1)}
      >
        <Icon type="solid" name="arrow-left" />
      </Button>
      <form onSubmit={(e) => submitForm(e)} className="flex flex-row items-center gap-2 md:mx-16 text-lg">
        <Input
          aria-label="page number input"
          defaultValue={props.page || 1}
          type="number"
          className="pagination-input appearance-none text-center outline outline-1 max-w-[3rem]"
        />
        <span className="font-bold pagination-input-divider">/</span>{' '}
        <span className="pagination-input-total">{props.totalPages || '1'}</span>
      </form>
      <Button
        type="button"
        variant="outline"
        disabled={props.page === props.totalPages}
        className="pagination-arrow"
        aria-label="next page"
        onClick={() => navigate(props.page + 1)}
      >
        <Icon type="solid" name="arrow-right" />
      </Button>
    </div>
  );
};

export { PaginationInput };
