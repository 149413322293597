import { useLoaderData } from '@remix-run/react';
import { useState, useCallback } from 'react';
import type { ListData } from '~/models/list-data.model';
import type { Localization } from '~/models/localization.model';
import type { PageData } from '~/models/page-data.model';
import { Icon } from '../commons/Icon';

const LocalePicker = ({ className }: any) => {
  const data = useLoaderData<PageData | ListData>();
  const [dropdownOpen, setDropdownOpen] = useState(false);

  const metadata = data?.metadata;
  const currentLocale = metadata?.locale;
  const otherLocales = metadata?.availableLocales?.filter((x) => x !== currentLocale);
  const pageLocales = metadata?.localization;

  const handleDropdown = () => {
    setDropdownOpen(!dropdownOpen);
  };

  const handleKeyBlur = useCallback((e: any) => {
    const currentTarget = e.currentTarget;
    requestAnimationFrame(() => {
      if (!currentTarget.contains(document.activeElement)) {
        setDropdownOpen(false);
      }
    });
  }, [setDropdownOpen]);

  const redirectToLocale = (url?: string) => {
    if (url) {
      window.location.href = url.startsWith('/') ? url : '/' + url;
    }
  };

  return (
    <>
      {currentLocale && otherLocales && otherLocales.length > 0 && (
        <button
          type="button"
          className={`language-selector bg-white rounded-lg relative text-base lg:text-xs uppercase font-secondary font-bold flex flex-row ${className}`}
          onBlur={handleKeyBlur}
          onClick={handleDropdown}
        >
          <div className="selected w-14 py-2 px-2.5 flex flex-row justify-between items-center gap-2">
            {currentLocale}{' '}
            <Icon
              name={dropdownOpen ? 'chevron-up' : 'chevron-down'}
              type="solid"
              className="text-xs -rotate-90 lg:rotate-0"
            />
          </div>
          {dropdownOpen && (
            <div className="options lg:absolute lg:top-10 ml-4 lg:ml-0 bg-white shadow-xl w-full rounded-lg overflow-hidden flex flex-row lg:flex-col">
              {otherLocales
                ?.sort((a, b) => a.localeCompare(b))
                .filter((locale) => locale !== currentLocale)
                .map((locale, index) => (
                  <>
                    {pageLocales && pageLocales[locale] ? (
                      <a
                        key={index}
                        href="/"
                        className="option hover:bg-neutral-50 p-2 w-full min-w-[4rem] lg:min-w-0 focus-visible:-outline-offset-8"
                        onClick={(e) => {
                          e.preventDefault();
                          redirectToLocale(getLocalizationUrl(metadata?.localization, locale));
                        }}
                      >
                        {locale}
                      </a>
                    ) : (
                      <div
                        key={index}
                        style={{ color: '#a3a3a3', cursor: 'default' }}
                        className="option disabled hover:bg-neutral-50 p-2 w-full min-w-[4rem] lg:min-w-0"
                      >
                        {locale}
                      </div>
                    )}
                  </>
                ))}
            </div>
          )}
        </button>
      )}
    </>
  );
};

const getLocalizationUrl = (localization: Localization | undefined, locale: string) => {
  if (localization === undefined) {
    return undefined;
  }

  return localization[locale];
};

export { LocalePicker };
